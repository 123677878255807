import React from 'react';

// Styles
import { Container, TextArea } from './FirstBoxButtons.styles';

// Components
import Button from 'components/atoms/Button/Button';
import Title from 'components/atoms/Title/Title';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';

const FirstBoxButtons: React.FC = () => {
	const [, setCookie] = useCookies(["consentIdForChange", "consentRenew"]);
	const navigate = useNavigate();
	const handleSubmit = () => {
		navigate(`/home`);
	};

	const handleSubmitMyShares = () => {
		navigate(`/meus-compartilhamentos`);
	};

	setCookie("consentIdForChange", null, {
		path: "/",
		expires: new Date(),
	});
	setCookie("consentRenew", null, {
		path: "/",
		expires: new Date(),
	});

	return (
		<Container className="columns-12 container mx-auto md:columns-6">
			<div className="hidden md:block">
				<TextArea>Compartilhe os seus dados com a Prudential do Brasil e obtenha vantagens e benefícios personalizados através do Open Insurance.</TextArea>
			</div>
			<div>
				<Title>Olá, o que você deseja fazer?</Title>
				<Button onClick={handleSubmit} data-testid="bringDataBTN" layout='base'>Trazer Dados</Button>
				<Button onClick={handleSubmitMyShares} data-testid="sharesBTN" layout='inverse'>Gerenciar meus compartilhamentos</Button>
			</div>
		</Container>
	);
}

export default FirstBoxButtons;