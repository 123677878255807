import React from 'react';

// Styles
import { Container } from './PostDate.styles';

// Interface
interface PostDateProps {
	children: React.ReactNode;
}

const PostDate: React.FC<PostDateProps> = ({ children }) => {
	return (
		<Container>{children}</Container>
	);
}

export default PostDate;