import styled from 'styled-components';

export const Container = styled.div`

  .checkboxTitle {
    font-size: 14px;
    color: #333333;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    @media (min-width: 600px) {
      justify-content: flex-end;
    }
  }

  .checkboxSubtitle {
    font-size: 18px;
    color: #222222;
  }

  .detailInfo {
    font-size: 15px;
    color: #1a1d1e;
  }
`;

export const Button = styled.button`
  background-color: #07639d;
  border-radius: 3px;
  border: solid 1px #07639d;
  color: #fff;
  display: block;
  font-size: 15px;
  height: 45px;
  margin: 0 auto;
  text-align: center;
  width: 232px;

  &:disabled {
    background-color: #afafaf;
    color: #999999;
    border: 1px solid #afafaf
  };
`;

export const SwitchToggle = styled.label`
  position: relative;
  display: inline-block;
  width: 54px;
  height: 27px;
  outline: none;

  input {
    position: absolute;
    opacity: 0;
  }
  
  span { 
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 34px;
  }
  span:before {
    position: absolute;
    content: "";
    height: 19px;
    width: 19px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
  }
  input:checked + span {
    background-color: #2196F3;
  }
  input:focus + span {
    box-shadow: 0 0 1px #2196F3;
  }
  input:checked + span:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  input[disabled] + span{
    background-color: rgba(33, 150, 243, .5);;
  }
`;
