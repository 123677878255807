import styled from 'styled-components';

export const Container = styled.h3`
	font-family: Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
	font-size: 14px;
	font-weight: 700;
	color: #ffffff;
	margin-left: 8px;
	padding-left: 20px;
`;
