import styled from "styled-components";

export const Container = styled.div`
  color: #1a1d1e;
  font-size: 16px;
  padding-bottom: 32px;
  padding-left: 16px;
  padding-right: 16px;

  @media (min-width: 768px) {
    margin: 0 auto;
    width: 80%;
  }
`;
