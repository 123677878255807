enum StatusConsent {
    AUTHORISED,
    AWAITING_AUTHORISATION,
    REJECTED
}

function isReject(status: string){
    return 'REJECTED' === status;
}

export {isReject, StatusConsent};