import styled, { css } from "styled-components";

interface Props {
  status: "active" | "inactive";
}

export const Container = styled.button<Props>`
  border-bottom: 2px solid;
  border-color: ${({ status }) =>
    status === "active" ? "#0066cc" : "transparent"};
  color: #0066cc;
  font-size: 14px;
  margin-right: 12px;
  padding: 8px 16px;
  transition: border-color ease-in-out 0.3s;

  ${({ status }) =>
    status === "active" &&
    css`
      font-weight: bold;
    `}

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    border-color: #0066cc;
    transition: border-color ease-in-out 0.3s;
  }

  @media (min-width: 600px) {
    border-bottom: 4px solid;
    border-color: ${({ status }) =>
      status === "active" ? "#0066cc" : "transparent"};
    font-size: 17px;
    width: 100%;
  }
`;
