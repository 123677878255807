import styled, { css } from "styled-components";

interface BoxInformationProps {
  fullWidth?: boolean;
}

export const Container = styled.div`
  background-color: #e5e7eb;
`;

export const Box = styled.div`
  background-color: #fff;
  border: 1px solid #9ca3af;
  margin: 0 auto;
  max-width: 448px;
  width: 100%;

  @media (min-width: 600px) {
    max-width: none;
  }
`;

export const BoxGroupInformation = styled.div`
  @media (min-width: 600px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;

    &:last-child {
      margin-top: 24px;
    }
  }
`;

export const BoxInformation = styled.div<BoxInformationProps>`
  margin: 16px 0;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  @media (min-width: 600px) {
    flex-basis: 22%;
    margin: 0;

    ${({ fullWidth }) =>
      fullWidth === true &&
      css`
        flex-basis: 100%;
      `};
  }
`;

export const BoxLinks = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;

  @media (min-width: 600px) {
    flex-direction: row;
    justify-content: flex-start;
    column-gap: 16px;
  }
`;

export const ModalBoxButtons = styled.div`
  @media (min-width: 768px) {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    column-gap: 16px;
    margin: 24px 0 0;
  }
`;
