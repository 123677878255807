import React, { ButtonHTMLAttributes } from 'react';

// Styles
import { Container } from './TabsButtons.styles';

// Interface
interface TabsButtonsProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	children: React.ReactNode;
	status: 'active' | 'inactive';
}

const TabsButtons: React.FC<TabsButtonsProps> = ({ children, status, ...rest }) => {
	return (
		<Container {...rest} status={status}>{ children }</Container>
	);
}

export default TabsButtons;