import React, { ButtonHTMLAttributes } from "react";

import { Container } from "./ButtonPagination.styles";

interface ButtonPaginationProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  pageNumber: number;
  handlePageChange: any;
  tipo: string;
  isActive: boolean;
}

const ButtonPagination: React.FC<ButtonPaginationProps> = ({
  pageNumber,
  handlePageChange,
  tipo,
  isActive,
}) => {
  return (
    <Container
      data-testid="buttonsPagination"
      isActive={isActive}
      onClick={() => {
        handlePageChange(pageNumber, tipo);
      }}
    >
      {pageNumber}
    </Container>
  );
};

export default ButtonPagination;
