import React from "react";

// Assets
import OpenInsuranceLogo from "assets/img/open-insurance.svg";

// Styles
import { Container, OpenInsurance, BannerContainer } from "./FirstHead.styles";

interface FirstHeadProps {
  noMobile?: boolean;
}

const FirstHead: React.FC<FirstHeadProps> = ({ noMobile }) => {
  return (
    <Container noMobile={noMobile}>
      <BannerContainer>
        <OpenInsurance src={OpenInsuranceLogo} alt="Open Insurance" />
      </BannerContainer>
    </Container>
  );
};

export default FirstHead;
