import React, { useState } from 'react';

// Styles
import { Container } from './Accordion.styles';

// Components
import AccordionTitle from 'components/atoms/AccordionTitle/AccordionTitle';
import AccordionContent from 'components/atoms/AccordionContent/AccordionContent';

// Interface
interface AccordionProps {
	title: string;
	content: React.ReactNode;
	isOpen?: boolean;
}

const Accordion: React.FC<AccordionProps> = ({ title, content, isOpen }) => {
	const [open, setOpen] = useState<boolean>(isOpen ? isOpen : false);

	return (
		<Container>
			<AccordionTitle data-testid="openAccordion" onClick={() => {setOpen(!open)}} status={open}>{title}</AccordionTitle>
			<AccordionContent status={open}>{content}</AccordionContent>
		</Container>
	);
}

export default Accordion;