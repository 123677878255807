import styled, { css } from "styled-components";

interface Props {
  layout: "base" | "inverse" | "text" | "action" | "yellow";
  spacing?: "base" | "small";
  modal?: boolean;
  modalLastOrder?: boolean;
}

export const Container = styled.button<Props>`
  border-radius: 3px;
  font-size: 16px;
  margin: 24px 0 0;
  padding: 10px 15px;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  width: 100%;

  &:disabled {
    background-color: #afafaf;
    color: #999999;
    border: 1px solid #afafaf;

    &:hover {
      background-color: #afafaf;
      color: #999999;
    }
  }

  ${({ spacing }) =>
    spacing === "small" &&
    css`
      margin: 16px 0 0;
    `}

  ${({ layout }) =>
    layout === "base" &&
    css`
      background-color: #07639d;
      border: solid 1px #07639d;
      color: #ffffff;
    `}

	${({ layout }) =>
    layout === "yellow" &&
    css`
      background-color: #ffd71e;
      border: solid 1px #ffd71e;
      color: #1a1d1e;

      &:hover {
        background-color: transparent;
        color: #ffd71e;
      }
    `}

	${({ layout }) =>
    layout === "inverse" &&
    css`
      background-color: transparent;
      border: solid 1px #07639d;
      color: #07639d;

      &:hover {
        background-color: #07639d;
        color: #ffffff;
      }
    `}

	${({ layout }) =>
    layout === "text" &&
    css`
      color: #0066cc;
      font-size: 15px;
      transition: text-decoration 0.3s ease-in-out;

      &:hover {
        text-decoration: underline;
        transition: text-decoration 0.3s ease-in-out;
      }
    `}

	${({ layout }) =>
    layout === "action" &&
    css`
      background-color: transparent;
      border-radius: 3px;
      border: solid 1px;
      border-color: #999;
      color: #1a1d1e;
      margin: 0 16px 0 0;
      padding: 2px 0;
      transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out,
        border-color 0.3s ease-in-out;

      &:hover {
        background-color: #07639d;
        color: #ffffff;
        border-color: #07639d;
        transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out,
          border-color 0.3s ease-in-out;
      }

      &:last-child {
        margin-right: 0;
      }
    `}

		@media (min-width: 768px) {
    ${({ modal }) =>
      modal &&
      css`
        width: fit-content;
        font-size: 15px;
        margin: 0;
      `}

    ${({ modalLastOrder }) =>
      modalLastOrder &&
      css`
        order: 1;
      `}
  }
`;
