import React from 'react';

// Styles
import { Container } from './BoxGroupContent.styles';

// Interface
interface BoxGroupContentProps {
	children: React.ReactNode;
	align?: 'center' | 'left' | 'right';
}

const BoxGroupContent: React.FC<BoxGroupContentProps> = ({ children, align }) => {
	return (
		<Container align={align}>{children}</Container>
	);
}

export default BoxGroupContent;