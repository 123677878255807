import styled from 'styled-components';

export const Container = styled.div`
	
  .inputInstitution {
    font-size: 15px;
    color: #666666;

    &::placeholder {
      color: #666666;
    }
  }
`;

export const Button = styled.button`
  cursor: pointer;
  background-color: #07639d;
  color: #fff;
  font-size: 15px;
  text-align: center;
  width: 232px;
  height: 45px;
  display: block;
  margin: 0 auto;
  border-radius: 3px;
  border: solid 1px #07639d;
  &:disabled {
    cursor: default;
    background-color: #afafaf;
    color: #999999;
    border: 1px solid #afafaf
  };
`;

export const Input = styled.input`
  background-color: #ffffff;
  color: #fff;
  border: 1px solid #868686;
  &[type="radio"]:checked {
    background-color:#333333;

    color: #ffffff;
    border: 1px solid #afafaf;
  }
`

export const Link = styled.a`
  color: #666666;
  cursor:pointer;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
`;
export const AccordionBox = styled.div`
	margin-top: 20px;
  margin-left: 15px;
  margin-right: 15px;
`;