export const ifTrueCallAndReturn = (ifTrue: boolean | null, valueIfTrue: () => any, valueIfFalse: () => any = () => { return null; }) => {
	if (ifTrue) {
		return valueIfTrue();
	}
	return valueIfFalse();
}

export const ifTrueCall = (ifTrue: boolean | null, callIfTrue: () => void, callIfFalse: () => void = () => { return null; }) => {
	if (ifTrue) {
		callIfTrue();
	} else {
		callIfFalse();
	}
}
