import Callback from "pages/Callback/Callback";
import MyShares from "pages/MyShares/MySharesComponent";
import ShareInformation from "pages/ShareInformation/ShareInformation";
import Terms from "pages/Terms/Terms";
import { Fragment } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
// Pages
import Home from "../pages/Home/HomeComponent";
import Start from "../pages/Start/StartComponent";
// Styles
import GlobalStyle from '../styles/Global';

const RoutesApp = () => {
  return (
    <BrowserRouter>
      <Fragment>
        <Routes>
          <Route path="/callback" element={<Callback />} />
          <Route path="/termos-de-uso" element={<Terms />} />
          <Route path="/compartilhamento/:shareId" element={<ShareInformation />} />
          <Route path="/meus-compartilhamentos" element={<MyShares />} />
          <Route path="/home" element={<Home />} />
          <Route path="/" element={<Start />} />
        </Routes>
        <GlobalStyle />
      </Fragment>
    </BrowserRouter>
  );
};

export default RoutesApp;
