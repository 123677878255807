import styled from 'styled-components';

export const Container = styled.button`
	display: flex;
	align-items: center;
	color: #222222;
	font-size: 18px;
	justify-content: flex-start;
	padding: 20px 16px;
	width: 100%;
	text-align: left;

	.icon {
		margin-right: 10px;
		width: auto;
		min-width: 18px;
		height: 18px;
	}
`;
