import React from 'react';

import { Container, Text, BoxEllipsis, Ellipsi } from './Loading.styles';

interface LoadingProps {
	text: string;
}

const Loading: React.FC<LoadingProps> = ({ text }) => {
	return (
		<Container>
			<Text>{ text }</Text>
			<BoxEllipsis>
				<Ellipsi />
				<Ellipsi />
				<Ellipsi />
				<Ellipsi />
			</BoxEllipsis>
		</Container>
	);
}

export default Loading;