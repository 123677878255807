import React from 'react';

// Styles
import { Container } from './BoxContentInformation.styles';

// Components
import InformationTitle from 'components/atoms/InformationTitle/InformationTitle';
import InformationContent from 'components/atoms/InformationContent/InformationContent';
import TextStrong from 'components/atoms/TextStrong/TextStrong';

// Interface
interface BoxContentInformationProps {
	title: string | undefined;
	content: string | undefined;
	contentStrong?: boolean;
}

const BoxContentInformation: React.FC<BoxContentInformationProps> = ({ title, content, contentStrong }) => {
	return (
		<Container>
			<InformationTitle>{title}</InformationTitle>
			{ contentStrong ? <TextStrong>{content}</TextStrong> : <InformationContent>{content}</InformationContent>} 
		</Container>
	);
}

export default BoxContentInformation;