import React from 'react';

// Styled
import { Container } from './TextStrong.styles';

// Interface
interface TextStrongProps {
	children: React.ReactNode;
}

const TextStrong: React.FC<TextStrongProps> = ({ children }) => {
	return (<Container>{children}</Container>);
}

export default TextStrong;