import React, { ImgHTMLAttributes } from 'react';

import { Container } from './SocialIcon.styles';

// Interfaces
interface SocialIconProps extends ImgHTMLAttributes<HTMLImageElement> {}

const SocialIcon: React.FC<SocialIconProps> = ({...props}) => {
	return (
		<Container {...props} />
	);
}

export default SocialIcon;