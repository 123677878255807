import React from "react";

// Styles
import { Container, Box } from "./StepTerms.styles";

// Atoms
import BackButton from "components/atoms/BackButton/BackButton";
import PostText from "components/atoms/PostText/PostText";

// Molecules
import BoxPostAccordion from "components/molecules/BoxPostAccordion/BoxPostAccordion";

// Organisms
import PostHeader from "components/organisms/PostHeader/PostHeader";

const StepTerms: React.FC = () => {
  return (
    <Container>
      <Box>
        <BackButton noDesktop>Voltar</BackButton>
        <PostHeader
          sessionTitle="Termos de uso"
          title="Política de privacidade da Prudential do Brasil"
          reading="5 minutos"
          date="Data da última atualização: 01 de setembro de 2022"
        />
        <PostText>
          Prezando pela transparência e pela manutenção de um relacionamento de
          confiança com os nossos clientes e usuários visitantes do site
          https://www.prudential.com.br/(“Site”), a{" "}
          <strong>PRUDENTIAL DO BRASIL</strong> elaborou este documento, que tem
          como objetivo apresentar as informações sobre o Tratamento de Dados
          Pessoais, como a coleta, o uso e o compartilhamento de suas
          informações quando você̂ contrata nossos produtos e/ou serviços, acessa
          o nosso Site ou se relaciona conosco.
        </PostText>
        <BoxPostAccordion />
        <PostText>
          Data da ultima atualização:
          <br />
          01 de setembro de 2022
        </PostText>
      </Box>
    </Container>
  );
};

export default StepTerms;
