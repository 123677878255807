import { Disclosure } from "@headlessui/react";
import { ChevronRightIcon } from "@heroicons/react/24/solid";
import StP2InformationLink from "components/atoms/InformationLink/InformationLink";
import StP2BoxContentInformation from "components/molecules/BoxContentInformation/BoxContentInformation";
import StP2BoxTextContent from "components/molecules/BoxTextContent/BoxTextContent";
import ModalParticipantes from "components/molecules/ModalParticipantes/ModalParticipantes";
import StP2InformationBox from "components/organisms/InformationBox/InformationBox";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { PermissionGroup, PermissionKeyName, SharedDataInterface } from "shared/interfaces/home.interface";
import { ApiService, Organizacao } from "shared/services/generic/GenericService";
// Styles
import { useCookies } from "react-cookie";
import { Button, Container, SwitchToggle } from "./Step2.styles";
import { KeySharedDataType, SharedDataType, financialAssistenceKeys, keysOfGroup, labelsOfGroup, registerDataBusinessKeys, registerDataPersonalKeys, transactionalDataKeys } from "shared/permissionKeys.constants";
import BoxPermissions from "components/molecules/BoxPermissions/BoxPermissions";
import { ifTrueCall, ifTrueCallAndReturn } from "utils/comparative.functions";

interface StepForm {
	nextStep: (step: number) => void,
	setPermissoes: (permissoes: any[]) => void,
	setTitle: (title: React.ReactNode) => void;
	dados: any
}

type SelectedItemType = { group_name: string, key_name: PermissionKeyName };

const setItemSelecionadoQuantoRenovacaoOuAlteracao = (cookies: any, setItemSelecionados: (vl: any) => void, permissoes: any) => {
	if (cookies.consentRenew || cookies.consentIdForChange) {
		setItemSelecionados(permissoes);
	}
}

const setWhenTrue = (checked: boolean | null, setItemSelecionados: (vl: any) => void, value: {}[], whenFalse: {}[]) => {
	if (checked) {
		setItemSelecionados(value);
	} else {
		setItemSelecionados(whenFalse);
	}
}


const hasIncludeAllPermissions = (cookies: any) => {
	return !!cookies.customerDocument && !!cookies.businessDocument;
}

const divLoad = (message: string = '', size: string = '2.5rem', height: string = 'auto') => {
	return (<>
		<div className="text-center" style={{ margin: "20px", "height": height }}>
			<div role="status">
				<svg style={{ "width": size }} className="inline mr-2 w-10 h-10 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
					<path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
				</svg>
				{message !== '' && <span className="sr-only" style={{ "overflow": "auto", "position": "inherit" }}>{message}</span>}
			</div>
		</div>
	</>)
}

const showDocumentFormated = (type: any = '', customerDocument: string = '') => {
	const document = customerDocument + "";
	let content = document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
	if (type === 'CNPJ') {
		content = document.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
	}
	return <StP2BoxContentInformation title={type} content={content} />;
}

const openDisclosure = (open: boolean) => {
	return `${(open ? 'rotate-90 transform' : '')} h-5 w-5 text-gray-900 text-bold`
}

const apiService = new ApiService();

const Step2: React.FC<StepForm> = ({ nextStep, setPermissoes, dados, setTitle }) => {
	const { register } = useForm();

	const [cookies] = useCookies(['consentRenew', 'consentIdForChange', 'customerDocument', 'businessDocument']);
	const [itemSelecionados, setItemSelecionados] = useState<SelectedItemType[]>([]);
	const [shareModal, setShareModal] = useState<boolean>(false);
	const [organization, setOrganization] = useState<Organizacao>();
	const [organizacaoLoad, setOrganizacaoLoad] = useState<boolean>(true);
	const [messageReturn, setMessageReturn] = useState<string>();
	const [sharedData, setSharedData] = useState<SharedDataType>({});

	const [receiverData, setReceiverData] = useState<SharedDataInterface>({
		type: null,
		groups: [],
	});

	useEffect(() => {
		if (receiverData && receiverData.groups) {
			const registerDataBusiness = receiverData.groups.filter(g => registerDataBusinessKeys.includes(g.key_name));
			const registerDataPersonal = receiverData.groups.filter(g => registerDataPersonalKeys.includes(g.key_name));
			const financialAssistence = receiverData.groups.filter(g => financialAssistenceKeys.includes(g.key_name));
			const transactionalData = receiverData.groups.filter(g => transactionalDataKeys.includes(g.key_name));

			setSharedData({
				registerDataBusiness,
				registerDataPersonal,
				financialAssistence,
				transactionalData
			});

			setItemSelecionados([...registerDataPersonal, ...registerDataBusiness, ...financialAssistence, ...transactionalData])
		}
	}, [receiverData])

	const consultData = () => {
		setItemSelecionados([]);
		setReceiverData({
			type: null,
			groups: [],
		});
		apiService
			.getPermissoes(dados.documentType, hasIncludeAllPermissions(cookies))
			.then((response: any) => {
				const data = response as SharedDataInterface;
				setReceiverData(data);
			});
		apiService.getOrganizationBy(dados.seguro.organizationId,dados.seguro.authorisationServerId)
			.then((org: Organizacao) => {
				setOrganization(org);
				setOrganizacaoLoad(false);
			}).catch((error: any) => {
				setMessageReturn(error.response?.data?.errorDetail?.errorContent?.errors[0]);
			}).finally(() => {
				setOrganizacaoLoad(false);
			});
	};
	useEffect(() => {
		window.scrollTo(0,0)
	  },[])
	useEffect(() => {
		setTitle(<StP2BoxTextContent key='title' typeContent="title" textContent="Escolha os seus dados" />)
		consultData();
		setItemSelecionadoQuantoRenovacaoOuAlteracao(cookies, setItemSelecionados, dados.permissoes);
	}, []);


	const selectAllInGroup = (checked: boolean, key: KeySharedDataType) => {
		const items = sharedData[key]?.flatMap((item: PermissionGroup) => ({ group_name: item.group_name, key_name: item.key_name }));

		setWhenTrue(
			checked && !!items,
			setItemSelecionados,
			[...itemSelecionados, ...items ?? []],
			itemSelecionados.filter(i => !items.some(it => it.key_name === i.key_name))
		);
	}

	const onSelectItem = (checked: boolean, key_name: any, key_group: KeySharedDataType) => {
		let resultante = itemSelecionados.filter((i: any) => i.key_name !== key_name);
		const map: any = sharedData[key_group].flatMap((item: any) => ({ group_name: item.group_name, key_name: item.key_name }));
		let listF: [] = map.filter((i: any) => i.key_name === key_name);
		setWhenTrue(checked, setItemSelecionados, [...resultante, ...listF], [...resultante]);
	}

	const isProductChecked = (key_name: any): boolean => {
		return ifTrueCallAndReturn(cookies.consentRenew, () => {
			return dados.permissoes.filter((i: any) => i.key_name === key_name).length > 0;
		}, () => {
			return itemSelecionados.filter((i: any) => i.key_name === key_name).length > 0;
		});
	};

	const confirmarSelecao = () => {
		ifTrueCall(!cookies.consentRenew, () => setPermissoes(itemSelecionados), () => ({}));
		nextStep(3);
	}

	return (
		<Container className="container mx-auto">
			<StP2BoxTextContent key={'description'}
				typeContent="text"
				textContent="Autorize quais informações você deseja trazer da instituição selecionada."
			/>

			<div className="w-full pt-5">
				{Object.keys(sharedData).map((key, index) => (
					sharedData?.[key] && sharedData?.[key].length > 0 && <>
						<StP2BoxTextContent key={labelsOfGroup[key]} typeContent="subtitle" textContent={labelsOfGroup[key]} />
						<div className="mx-auto flex w-full max-w-full rounded-2xl bg-white px-6">
							<h1 className="w-full -ml-1 checkboxTitle">{
								(cookies.consentRenew || key === 'registerDataPersonal') 
								? 'Dados obrigatórios'
								: 'Selecionar disponíveis'
								}</h1>
							<div className="flex items-center ml-4">
								<SwitchToggle>
									<input
										key={key}
										disabled={(cookies.consentRenew || key === 'registerDataPersonal')}
										type="checkbox"
										id={key}
										data-testid="selectAllCheckbox"
										checked={keysOfGroup[key].every(permission => itemSelecionados.some(i => i.key_name === permission))}
										onChange={(e) => ifTrueCall(key !== 'registerDataPersonal', () => selectAllInGroup(e.target.checked, key))}
									/>
									<span></span>
								</SwitchToggle>
							</div>
						</div>
						<div className="mx-auto w-full max-w-full rounded-2xl bg-white p-2">
							{sharedData[key].map((group: PermissionGroup, currentKey: number) => (
								<Disclosure key={`${index}.${currentKey}`}>
									{({ open }) => (
										<>
											<Disclosure.Button className="flex w-full items-center py-4 ml-1 font-light border-b border-gray-200">
												<div className="flex">
													<ChevronRightIcon data-testid="openDisclosure" className={openDisclosure(open)} />
												</div>
												<span className="ml-4 flex w-full checkboxSubtitle">{group.group_name}</span>
												<div></div>
												<div className="flex items-center pr-5 pl-4">
													<SwitchToggle>
														<input
															disabled={(cookies.consentRenew || key === 'registerDataPersonal')}
															data-testid="inputType"
															{...register(group.key_name)}
															type="checkbox"
															key={group.key_name}
															name={group.key_name}
															checked={isProductChecked(group.key_name)}
															value={group.key_name}
															onChange={(e) => ifTrueCall(key !== 'registerDataPersonal', () => onSelectItem(e.target.checked, group.key_name, key))}
														/>
														<span></span>
													</SwitchToggle>
												</div>
											</Disclosure.Button>
											<Disclosure.Panel className="pl-5 py-2 font-light border-b border-gray-200">
												<p>
													{
														group.items!.map((item: any, keyItem: number) => {
															const isLast = keyItem === (group.items?.length ?? 0) - 1;
															
															return (<span className="detailInfo" key={`${index}.${currentKey}.${keyItem}`}>
																{item}
																{isLast ? '.' : ', '}
                                								
															</span>);
														})
													}
												</p>
											</Disclosure.Panel>
										</>
									)}
								</Disclosure>
							))
							}
						</div>
					</>
				))}
			</div>

			<StP2BoxTextContent
				typeContent="subtitle"
				textContent="Até agora você escolheu"
			/>

			<StP2InformationBox direction="column">
				<StP2BoxContentInformation title="Segurado" content={dados.name} />
				{showDocumentFormated(dados.documentType, dados.cpf)}

				
				<StP2BoxContentInformation
					title="Instituição selecionada"
					content={dados.seguro.name}
				/>

				{messageReturn && <div style={{ margin: "20px" }} className="flex p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800" role="alert">
					<svg aria-hidden="true" className="flex-shrink-0 inline w-5 h-5 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
					<span className="sr-only">Info</span>
					<div>
						<span className="font-medium">Erro!</span> {messageReturn}
					</div>
				</div>}

				{organizacaoLoad && divLoad('Carregando participantes...', '20px')}

				{!organizacaoLoad && !messageReturn &&
					<StP2InformationLink data-testid="openModalBTN" onClick={() => (setShareModal(true))}>
						Ver detalhes do participante
					</StP2InformationLink>
				}
				<div className="border border-2 border-gray-200 mt-4 mb-4"></div>
				<BoxPermissions permissoes={itemSelecionados} />
			</StP2InformationBox>
			<div className="w-full flex justify-center mb-6" style={{ "marginTop": "20px" }}>
				<Button data-testid="confirmBTN" type="submit" onClick={confirmarSelecao} className="bg-gray-200 py-2 w-1/2 flex justify-center items-center "
					disabled={itemSelecionados.length === 0}>Continuar</Button>
			</div>

			<ModalParticipantes organization={organization} setShareModal={setShareModal} shareModal={shareModal} />
		</Container>
	);
};

export default Step2;
