import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	padding: 8px 0;
	margin-left: 8px;
	gap: 24px;
	color: #ccc;
	padding: 0 20px;
	width: 100%;

`;
