import styled from 'styled-components';

export const Container = styled.h1`
	object-fit: contain;
  color: #333;
  font-family: "Prudential Modern";
  font-size: 28px;
  font-stretch: normal;
  font-style: normal;
  font-weight: bold;
  letter-spacing: normal;
  line-height: 1.07;
`;

export const HR = styled.hr`
  margin-top: 14px;
`