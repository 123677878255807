import React from 'react';

import { Container } from './Subtitle.styles';

// Interface
interface SubtitleProps {
	children: React.ReactNode;
}

const Subtitle: React.FC<SubtitleProps> = ({ children }) => {
	return <Container>{children}</Container>;
}

export default Subtitle;