import styled, { css } from 'styled-components';

// Interface
interface Props {
	type?: 'base' | 'blue' | 'icon'
}

export const Container = styled.a<Props>`
	display: flex;
	align-items: center;
	margin-right: 8px;
	font-size: 15px;
	cursor: pointer;

	${({ type }) => type === 'blue' &&
		css`
			color: #0066cc;

			&:hover {
				text-decoration: underline
			}
		`
	};

	${({ type }) => type === 'icon' &&
		css`
		border: 1px solid #fff;
		border-radius: 50%;
		padding: 8px;
		-webkit-transition: all .3s ease-in-out;
		transition: all .3s ease-in-out;
		&:hover {
			background-color: #fff;
			-webkit-transform: scale(1.25);
			transform: scale(1.25);
			img{
				-webkit-filter: invert(1) opacity(.5) drop-shadow(0 0 #202020);
    			filter: invert(1) opacity(.5) drop-shadow(0 0 #202020);
			}
		}
		img{	
			background-position: 50%;
			background-size: 100% 100%;
			background-size: cover;
			-webkit-filter: opacity(.5) drop-shadow(0 0 #202020);
			filter: opacity(.5) drop-shadow(0 0 #202020);
			-webkit-transition: all .3s ease-in-out;
			transition: all .3s ease-in-out;
			max-width: 100%;
    		height: auto;
			max-height: 23px;
			max-width: 23px;
		}
		`
	};

	svg {
		display: initial;
		margin-right: 4px;
		width: 20px;
	}

	&:last-child {
		margin-right: 0;
	}
`;
