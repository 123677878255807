import React from "react";

// Styles
import { Container } from "./TabsBoxContent.styles";

// Interface
interface TabsBoxContentProps {
  children: React.ReactNode;
  status: "active" | "inactive";
}

const TabsBoxContent: React.FC<TabsBoxContentProps> = ({
  children,
  status,
}) => {
  return <Container status={status}>{children}</Container>;
};

export default TabsBoxContent;
