import React from 'react';

import { Container } from './ShareSeparator.styles';

const ShareSeparator: React.FC = () => {
	return (
		<Container />
	);
}

export default ShareSeparator;