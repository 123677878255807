import styled from "styled-components";

export const Container = styled.div`
  color: #1a1d1e;
  font-size: 15px;
  font-weight: 600;
  margin-top: 20px;

  @media (min-width: 768px) {
    margin-top: 32px;
  }
`;
