import React from 'react';

// Styles
import { Container, BoxButtons } from './BoxShareActions.styles';

// Components
import ShareText from 'components/atoms/ShareText/ShareText';

// Interface
interface BoxShareActionsProps {
	title: string;
	children: React.ReactNode;
}

const BoxShareActions: React.FC<BoxShareActionsProps> = ({ title, children }) => {
	return (
		<Container>
			<ShareText>{title}</ShareText>
			<BoxButtons>{children}</BoxButtons>
		</Container>
	);
}

export default BoxShareActions;