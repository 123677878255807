import React from 'react';

// Atoms
import BackButton from 'components/atoms/BackButton/BackButton';

// Organisms
import Footer from 'components/organisms/Footer/Footer';
import Header from 'components/organisms/Header/Header';
import StepBar from 'components/organisms/StepBar/StepBar';

// Templates
import StepCallback from 'components/templates/step-callback/StepCallback';

// Styles
import { Box, Container } from './Callback.styles';

const Callback: React.FC = () => {
	const stepListStatus = [
		{
			status: 'active',
			number: 1,
			name: "Instituições",
		},
		{
			status: 'active',
			number: 2,
			name: "Dados",
		},
		{
			status: 'active',
			number: 3,
			name: "Consentimentos",
		},
		{
			status: 'active',
			number: 4,
			name: "Aceite",
		},
	];

	return (
		<div>
			<Header />
			<Container>
				<Box>
					<BackButton urlCallBack={'/'}>Home</BackButton>
					<StepBar list={stepListStatus} />
					<StepCallback />
				</Box>
			</Container>
			<Footer />
		</div>
	);
}

export default Callback;