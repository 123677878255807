import React from 'react';

// Styled
import { Container } from './Text.styles';

// Interface
interface TextProps {
	children: React.ReactNode;
}

const Text: React.FC<TextProps> = ({ children }) => {
	return (<Container>{children}</Container>);
}

export default Text;