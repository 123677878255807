import React from 'react';

// Styles
import { Container } from './InformationBox.styles';

// Interface
interface InformationBoxProps {
	children: React.ReactNode;
	direction?: "column" | "column-reverse" | "row" | "row-reverse"
}

const InformationBox: React.FC<InformationBoxProps> = ({ children , direction }) => {
	return (
		<Container style={{flexDirection: direction ?? 'row'}}>{children}</Container>
	);
}

export default InformationBox;