import styled, { css } from "styled-components";
import Background from "assets/img/background.png";

interface Props {
  noMobile?: boolean;
}

export const Container = styled.div<Props>`
  background-color: #012451;
  padding-top: 16px;
  background-image: url(${Background});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right top;
  min-height: 420px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;

  @media (max-width: 600px) {
    ${({ noMobile }) =>
      noMobile &&
      css`
        display: none;
      `}
  }
`;

export const BannerContainer = styled.div`
  position: relative;
  padding-left: 130px;
  padding-bottom: 40px;
  @media (max-width: 768px) {
    padding-left: 1rem;
  }

  &::before {
    left: 0;
    top: -10px;
    content: "";
    position: absolute;
    min-width: 320px;
    min-height: 50px;
    background: white;
    z-index: 0;
    width: 370px;
    @media (max-width: 768px) {
      width: 320px;
    }
  }
`;

export const OpenInsurance = styled.img`
  max-width: 240px;
  position: relative;
  z-index: 1;
`;
