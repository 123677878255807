import { useEffect, useState } from "react";
import { PermissionGroup } from "shared/interfaces/home.interface";
import { SharedDataType, financialAssistenceKeys, labelsOfGroup, registerDataBusinessKeys, registerDataPersonalKeys, transactionalDataKeys } from "shared/permissionKeys.constants";

interface BoxPermissionsProps {
  permissoes?: PermissionGroup[]
}

const BoxPermissions: React.FC<BoxPermissionsProps> = ({ permissoes }) => {
  const [sharedData, setSharedData] = useState<SharedDataType>({});
  useEffect(() => {

    if (permissoes) {
      const registerDataBusiness = permissoes.filter((g: PermissionGroup) => registerDataBusinessKeys.includes(g.key_name));
      const registerDataPersonal = permissoes.filter((g: PermissionGroup) => registerDataPersonalKeys.includes(g.key_name));
      const financialAssistence = permissoes.filter((g: PermissionGroup) => financialAssistenceKeys.includes(g.key_name));
      const transactionalData = permissoes.filter((g: PermissionGroup) => transactionalDataKeys.includes(g.key_name));

      setSharedData({
        registerDataBusiness,
        registerDataPersonal,
        financialAssistence,
        transactionalData
      });
    }
  }, [permissoes]);

  return <ul style={{ padding: '0 15px' }}>
    {Object.keys(sharedData).map((key) => (
      sharedData?.[key] && sharedData?.[key].length > 0 && <>
        <li key={key}>
          {labelsOfGroup[key]}
          <ul style={{ listStyle: 'initial', padding: '0 30px' }}>
            {sharedData?.[key].map((permission: any) => (
              <li key={permission.key_name}>{permission.group_name}</li>
            ))}
          </ul>
        </li>
      </>
    ))}
  </ul>
}

export default BoxPermissions;