import React, { ButtonHTMLAttributes } from "react";

// Styles
import { Container } from "./Button.styles";

// Interface
interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  layout: "base" | "inverse" | "text" | "action" | "yellow";
  spacing?: "base" | "small";
  modal?: boolean;
  modalLastOrder?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  children,
  layout,
  spacing,
  modal,
  modalLastOrder,
  ...rest
}) => {
  return (
    <Container
      layout={layout}
      spacing={spacing}
      modal={modal}
      modalLastOrder={modalLastOrder}
      {...rest}
    >
      {children}
    </Container>
  );
};

export default Button;
