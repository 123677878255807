import React from 'react';


// Styles
import { Container } from './BoxLinks.styles';

// Components
import Anchor from 'components/atoms/Anchor/Anchor';

const BoxLinks: React.FC = () => {
    return (
        <Container>
            <Anchor href="https://www.prudential.com.br/termos-e-condicoes-de-uso.html" target="_blank" title="Termos de Uso - Prudential do Brasil" >
                Termos de Uso
            </Anchor>

            <Anchor href="https://www.prudential.com.br/privacidade.html" target="_blank" title="Política de Privacidade - Prudential do Brasil" >
                Política de Privacidade
            </Anchor>

            <Anchor href="https://www.prudential.com.br/manual-de-boas-praticas-da-comunidade.html" target="_blank" title="Manual de Boas Práticas da Comunidade - Prudential do Brasil" >
                Manual de Boas Práticas da Comunidade
            </Anchor>
            <Anchor className='ot-sdk-show-settings' title="Definições de cookies - Prudential do Brasil">
                Definições de cookies
            </Anchor>
        </Container>
    );
}

export default BoxLinks;