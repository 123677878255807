import styled from 'styled-components';

export const Container = styled.img`
	padding-left: 5px;
	height: 48px;

	@media (min-width: 768px) {
		padding-left: 0;
	}
`;
