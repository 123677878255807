import React from 'react';

// Styles
import { Container } from './ShareText.styles';

// Interface
interface ShareTextProps {
	children?: React.ReactNode;
}

const ShareText: React.FC<ShareTextProps> = ({ children }) => {
	return (
		<Container>{children}</Container>
	);
}

export default ShareText;