import React from "react";

// Styles
import {
  Container,
  Box,
  TitleBox,
  Title,
  Close,
  Line,
  ContentBox,
} from "./Modal.styles";

// Assets
import { XMarkIcon } from "@heroicons/react/24/solid";

// Interface
interface ModalProps {
  children: React.ReactNode;
  title: string;
  titleLine?: boolean;
  showModal: boolean;
  setShowModal: any;
  type?: "base" | "alternative";
  noClose?: boolean;
}

const Modal: React.FC<ModalProps> = ({
  children,
  title,
  titleLine,
  showModal,
  setShowModal,
  type,
  noClose,
}) => {
  return (
    <Container isOpen={showModal}>
      <Box type={type}>
        <TitleBox type={type}>
          <Title>{title}</Title>
          {!noClose && (
            <Close>
              <XMarkIcon
                data-testid="closeModal"
                onClick={() => {
                  setShowModal(!showModal);
                }}
              />
            </Close>
          )}
        </TitleBox>
        {titleLine && <Line />}
        <ContentBox type={type}>{children}</ContentBox>
      </Box>
    </Container>
  );
};

export default Modal;
