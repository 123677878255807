import React, { useEffect } from "react";
// Styles
import { Container, Logo, SeparatorBox } from "./FlowInstitutions.styles";

// Interface
interface FlowInstitutionsProps {
  urlRedirect: any;
  receiverLogo: string;
  transmitterLogo: string;
  customerDocument: string;
}

const TIME_TO_REDIRECT = 10000; //10 Secunds

const FlowInstitutions: React.FC<FlowInstitutionsProps> = ({
  urlRedirect,
  receiverLogo,
  transmitterLogo,
  customerDocument,
}) => {
  useEffect(() => {
    if (urlRedirect) {
      setTimeout(() => {
        window.location.href = urlRedirect.authorizationUrl;
      }, TIME_TO_REDIRECT);
    }
  }, [urlRedirect]);

  return (
    <Container>
      <Logo data-testid="receiverLogo" src={receiverLogo} />
      <SeparatorBox>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path d="M256 0C114.6 0 0 114.6 0 256S114.6 512 256 512s256-114.6 256-256S397.4 0 256 0zM127 297c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l71 71L232 120c0-13.3 10.7-24 24-24s24 10.7 24 24l0 214.1 71-71c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9L273 409c-9.4 9.4-24.6 9.4-33.9 0L127 297z" />
        </svg>
      </SeparatorBox>
      <Logo data-testid="transmitterLogo" src={transmitterLogo} />
    </Container>
  );
};

export default FlowInstitutions;
