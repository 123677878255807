import styled, { css } from "styled-components";

interface Props {
  status: "active" | "inactive";
}

export const Container = styled.div<Props>`
  margin: 24px 16px;

  ${({ status }) =>
    status === "active"
      ? css`
          display: block;
        `
      : css`
          display: none;
        `}

  @media (min-width: 600px) {
    ${({ status }) =>
      status === "active"
        ? css`
            display: flex;
          `
        : css`
            display: none;
          `}

    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;
    column-gap: 32px;
    row-gap: 32px;
    width: 80%;
    margin: 24px auto;
  }
`;
