
import Footer from "components/organisms/Footer/Footer";
import Header from "components/organisms/Header/Header";

// Templates
import Step0 from 'components/templates/step-0/Step0';
import { useSearchParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';

const Start: React.FC = () => {
  const [,setCookie] = useCookies(['customerDocument', 'businessDocument']);

  const [searchParams] = useSearchParams();
  const customerDocument = searchParams?.get("customerDocument") as string;
  var tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);


  if (customerDocument) {
    setCookie('customerDocument', customerDocument, { path: '/', expires: tomorrow });
  }

  const businessDocument = searchParams?.get("businessDocument") as string;
  if (businessDocument) {
    setCookie('businessDocument', businessDocument, { path: '/', expires: tomorrow });
  }

  return (
    <div>
      <Header />
      <div className="min-h-full w-full md:column sm:bg-gray-200 flex flex-col justify-center">
        <div className="w-full sm:bg-white">
          <Step0 />
        </div>
      </div>
      <Footer />
    </div>
  )
}
export default Start