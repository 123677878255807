import React, { AnchorHTMLAttributes } from "react";

// Styles
import { Container } from "./ShareAnchor.styles";

// Interface
interface ShareAnchorProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  children: React.ReactNode;
  quarterWidth?: boolean;
}

const ShareAnchor: React.FC<ShareAnchorProps> = ({
  children,
  quarterWidth,
  ...rest
}) => {
  return (
    <Container quarterWidth={quarterWidth} {...rest}>
      {children}
    </Container>
  );
};

export default ShareAnchor;
