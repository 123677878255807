import styled from 'styled-components';

export const Container = styled.div`
	background-color: #E5E7EB;	
`;

export const Box = styled.div`
	background-color: #fff;
	margin: 0 auto;
	width: 100%;
`;
