import React from 'react';

// Styles
import { Container } from './ShareTitleBox.styles';

// Components
import Title from 'components/atoms/Title/Title';

// Interface
interface ShareTitleBoxProps {
	title: string;
}

const ShareTitleBox: React.FC<ShareTitleBoxProps> = ({ title }) => {
	return (
		<Container>
			<Title>{title}</Title>
		</Container>
	);
}

export default ShareTitleBox;