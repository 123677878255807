import React from "react";

// Styles
import { Container } from "./MySharesComponent.styles";

// Molecules
import FirstHead from "components/molecules/FirstHead/FirstHead";

// Organisms
import Header from "components/organisms/Header/Header";
import Footer from "components/organisms/Footer/Footer";

// Templates
import StepShares from "components/templates/step-shares/StepShares";

const MyShares: React.FC = () => {
  return (
    <Container>
      <Header />
      <FirstHead noMobile />
      <StepShares />
      <Footer />
    </Container>
  );
};

export default MyShares;
