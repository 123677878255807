import styled from 'styled-components';

export const Container = styled.div`
	margin-bottom: 8px;
	margin-right: 1.3rem;

	&:last-child {
		margin-bottom: 0px;
	}
`;
