import styled from "styled-components";

export const Container = styled.div`
  border: 1px solid #cccccc;
  margin-bottom: 16px;
  margin-left: 16px;
  margin-right: 16px;
  padding: 16px 16px;

  @media (min-width: 600px) {
    width: 80%;
    margin: 0 auto 16px;
  }
`;
