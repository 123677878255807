import React from 'react';

// Styles
import { Container } from './PostText.styles';

// Interface
interface PostTextProps {
	children: React.ReactNode;
}

const PostText: React.FC<PostTextProps> = ({ children }) => {
	return (
		<Container>{children}</Container>
	);
}

export default PostText;