import styled, { css } from "styled-components";

interface Props {
  isActive: boolean;
}

export const Container = styled.button<Props>`
  border-radius: 3px;
  font-size: 16px;
  line-height: 16px;
  padding: 8px 12px 5px;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  background-color: transparent;
  border-radius: 3px;
  border: solid 1px;
  border-color: #999;
  color: #07639d;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out,
    border-color 0.3s ease-in-out;

  @media (min-width: 600px) {
    font-size: 16px;
    line-height: 16px;
    padding: 12px 16px 9px;
  }

  &:hover {
    background-color: #07639d;
    color: #ffffff;
    border-color: #07639d;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out,
      border-color 0.3s ease-in-out;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      background-color: #07639d;
      color: #ffffff;
      border-color: #07639d;
    `}
`;
