import styled, { css } from 'styled-components';

// Interface
interface Props {
	align?: 'center' | 'left' | 'right';
}

export const Container = styled.div<Props>`
	margin-bottom: 16px;
	display: flex;
	flex-direction: column;
	gap: 2px;

	${({ align }) => align === 'center' && css`align-items: center; text-align: center;`};
	${({ align }) => align === 'left' && css`align-items: flex-start; text-align: left;`};
	${({ align }) => align === 'right' && css`align-items: flex-end; text-align: right;`};
`;
