import React from 'react';

// Components
import FooterTitle from 'components/atoms/FooterTitle/FooterTitle';
import BoxSocials from 'components/molecules/BoxSocials/BoxSocials';

// Styles
import { Container, Box, Separator } from './Footer.styles';
import BoxLinks from 'components/molecules/BoxLinks/BoxLinks';

const Footer: React.FC = () => {
	return (
		<Container>
			<Box>
				<FooterTitle>Siga a Prudential em:</FooterTitle>
				<BoxSocials />
			</Box>
			<Separator />
			<Box>
				<BoxLinks />
			</Box>
			<Separator />
		</Container>
	);
}

export default Footer;