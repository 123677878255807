import React, {ButtonHTMLAttributes} from 'react';

// Components
import { ChevronRightIcon, ChevronDownIcon } from '@heroicons/react/24/solid';

// Styles
import { Container } from './AccordionTitle.styles';

// Interface
interface AccordionTitleProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	children: React.ReactNode;
	status: boolean;
}

const AccordionTitle: React.FC<AccordionTitleProps> = ({ children, status, ...rest }) => {
	return (
		<Container {...rest}>
			{status ? <ChevronDownIcon className='icon'/> : <ChevronRightIcon className='icon'/>} { children }
		</Container>
	);
}

export default AccordionTitle;