import React from 'react';

// Styles
import { Container, AccordionBox } from './FirstBoxAccordion.styles';

// Components
import Subtitle from 'components/atoms/Subtitle/Subtitle';
import Accordion from 'components/molecules/Accordion/Accordion';

const FirstBoxAccordion: React.FC = () => {
	return (
		<Container className="container mx-auto mt-5">
			<Subtitle>Dúvidas frequentes</Subtitle>
			<AccordionBox>
				<Accordion isOpen={false} title='O que é o Open Insurance?' content='O Open Insurance, também chamado de Sistema de Seguros Aberto, traz a possibilidade de consumidores de produtos e serviços de seguros, previdência complementar aberta e capitalização permitirem o compartilhamento de suas informações entre diferentes sociedades autorizadas/credenciadas pela SUSEP, de forma segura, ágil, precisa e conveniente. Para entregar esses benefícios ao consumidor, o Open Insurance operacionaliza e padroniza o compartilhamento de dados e serviços por meio de abertura e integração de sistemas, com privacidade e segurança.' />
				<Accordion title='É seguro compartilhar meus dados por meio do Open Insurance?' content='Preservar a segurança em relação aos dados e serviços compartilhados por titulares de dados é um dos principais objetivos do Open Insurance. Por isso, as sociedades participantes e demais organizações autorizadas a funcionar pela SUSEP no âmbito do Open Insurance devem cumprir uma série de requisitos para garantir a autenticidade, a segurança e o sigilo das informações compartilhadas. As sociedades participantes devem observar exigências previstas na legislação e regulamentação vigentes para assegurar a segurança e a confiabilidade do processo de compartilhamento, a exemplo da Lei Geral de Proteção de Dados e regras relativas à implementação de políticas de segurança cibernética.' />
				<Accordion title='Quais são as vantagens e benefícios de compartilhar meus dados por meio do Open Insurance?' content='Com o Open Insurance você compartilha os seus dados entre diferentes instituições autorizadas pela SUSEP para que possa receber ofertas personalizadas da sociedade receptora dos dados. Conhecendo melhor o seu perfil, a seguradora poderá oferecer produtos e serviços adequados às suas necessidades como cliente, resultando em melhores ofertas de produtos e serviços em termos de preços e condições comerciais.' />
				<Accordion title='Quais são os prazos possíveis de autorização de acesso aos meus dados compartilhados?' content='A autorização para acesso aos dados pela sociedade receptora terá validade máxima de até 12 meses, mas pode ser alterada ou revogada a qualquer momento. Afinal, você decide o que quer compartilhar e por quanto tempo.' />
				<Accordion title='Como cancelar ou revogar o meu consentimento para interromper o compartilhamento dos meus dados?' content='Você poderá cancelar ou revogar o seu consentimento, a qualquer momento, tanto através da sociedade receptora de seus dados, como através da sociedade transmissora.' />
				<Accordion title='Quem pode autorizar o compartilhamento dos meus dados com outras seguradoras?' content='Somente o titular dos dados (próprio segurado) poderá autorizar o compartilhamento de seus dados entre as sociedades participantes do Open Insurance. As seguradoras não poderão transmitir os dados pessoais a terceiros sem o consentimento expresso do segurado.' />
				<Accordion title='Será cobrado algum valor para o compartilhamento dos dados entre seguradoras?' content='Não será cobrado nenhum valor do segurado ou do responsável pelo pagamento da apólice pelo compartilhamento de seus dados.' />
				<Accordion title='Quais são os tipos de dados que posso compartilhar com outras seguradoras pelo Open Insurance?' content={
					<>
						<p>
							Poderão ser compartilhados seus dados pessoais e seus dados de movimentação de seguros entre as sociedades participantes do Open Insurance, conforme exemplos abaixo:
						</p>
						<p>
							<u>Dados pessoais:</u> Nome completo, CPF, endereço, data de nascimento, filiação, estado civil, nacionalidade, sexo, documento de identificação (tipo do documento, número, validade, data de emissão etc.), nacionalidade, renda informada, valor de patrimônio informado, ocupação etc.
						</p>
						<p><u>Dados do seguro de vida:</u> Número da apólice, coberturas contratadas, capitais segurados vigentes, valores de prêmios totalizados e por cobertura, histórico de sinistros por cobertura (quando aplicável), data de emissão da apólice, data de início e fim de vigência das coberturas contratadas, beneficiários, informações de franquia e carência (quando aplicável), periodicidade de pagamento dos prêmios, total de parcelas de prêmios, meio de pagamento utilizado, etc.

						</p>
					</>
				} />
				<Accordion title='Qual o canal de atendimento do Open Insurance?' content={
					<p>
						O Open Insurance dispõe de um portal de atendimento 24 horas destinado a todos os seus participantes, seja seguradora, corretora ou cliente, através do link: <a href='https://opinbrasil.com.br/' target='_blank'>https://opinbrasil.com.br/</a>
					</p>
				} />
			</AccordionBox>
		</Container>
	);
}

export default FirstBoxAccordion;