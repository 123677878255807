import moment from 'moment';
import defaultService from './DefaultService';
import { PermissionGroup, SharedDataInterface } from 'shared/interfaces/home.interface';
import { AxiosRequestHeaders } from 'axios';


interface Token {
    access_token?: string,
    token_type?: string,
    expires_in?: number,
}

let token: Token = {};

export class GenericService {
    basePath: string

    constructor() {
        const currentUrl = window.location.href;
        this.basePath = currentUrl.includes('localhost') ? '/api' : '/api/pob/open-insurance/bff-transmitter/api';
    };

    async get(path: string): Promise<any> {
        await this.updateTokenWhenNullOrExpired();

        return await defaultService.get(`${this.basePath}${path}`, {
            headers: {
                "X-POB-JWT": token.access_token as string
            }
        });
    }

    async getWithHeaders(path: string, headers?: AxiosRequestHeaders): Promise<any> {
        await this.updateTokenWhenNullOrExpired();

        return await defaultService.get(`${this.basePath}${path}`, {
            headers: {
                "X-POB-JWT": token.access_token as string,
                ...headers
            }
        });
    }

    async post(path: string, body: any = {}): Promise<any> {
        await this.updateTokenWhenNullOrExpired();

        return await defaultService.post(`${this.basePath}${path}`, body, {
            headers: {
                "X-POB-JWT": token.access_token as string
            }
        });
    }

    async delete(path: string): Promise<any> {
        await this.updateTokenWhenNullOrExpired();

        return await defaultService.delete(`${this.basePath}${path}`, {
            headers: {
                "X-POB-JWT": token.access_token as string
            }
        });
    }

    async patch(path: string, body: any = {}): Promise<any> {
        await this.updateTokenWhenNullOrExpired();

        return await defaultService.patch(`${this.basePath}${path}`, body, {
            headers: {
                "X-POB-JWT": token.access_token as string
            }
        });
    }

    private async auth() {
        const { data } = await defaultService.get('/token');
        token = data as Token;
        // token = {
        //     "access_token": "eyJraWQiOiJhcGktZGV2LnBydWRlbnRpYWwuY29tX2Z3UTdsYUhCeVBXNGZaT3ZYOEk5cnA2Nm45YyIsInR5cCI6IkpXVCIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiJDWTNrZk9JVjA4ZWhob0tkallibDJBYjVSQW14QXNYViIsImFnZW50VHlwZSI6IkxQIiwiYWdlbnRfaWQiOiIzMTI2NSIsInJvbGVzIjpbIiJdLCJjaGFubmVsIjoiOSIsImlzcyI6Imh0dHBzOlwvXC9hcGktZGV2LnBydWRlbnRpYWwuY29tIiwiY2hhbm5lbFR5cGUiOiJMUCIsImFwcF9uYW1lIjoib3Blbl9pbnN1cmFuY2UiLCJhdWQiOlsiaHR0cHM6XC9cL2VhcGktZGV2Y2VydC5wcnVkZW50aWFsLmNvbSIsImh0dHBzOlwvXC9hcGktZGV2LnBydWRlbnRpYWwuY29tIiwiQ1kza2ZPSVYwOGVoaG9LZGpZYmwyQWI1UkFteEFzWFYiXSwiaWRwIjoiQjJCIiwidXNlcl9pZCI6IlNSVk5VQkFOS0FQUCIsImNwZCI6IjMxMjY1IiwiZXhwIjoxNzIwNDUwMTEzLCJpYXQiOjE3MjA0NDgzMTMsImVtYWlsIjoiMzEyNjUuZW1haWxAcG9iLmNvbS5iciIsImp0aSI6ImNiYTI0MzY1LWUyNzktNDFkYy1iYjMyLWM5MGM3NzdjYWIxNyIsImRldmVsb3BlckFwcE5hbWUiOiJQT0JfT1BJTl9CRkZfVFJBTlNNSVRURVJfQVBQIn0.NFI7EgACpvhu03ZaEijZFfQc_FUfRhd9UC0THpOZb5zZ4Wuio8mfCw13WKuer8RVVkzUcLwlSIY02mGpch_xLAEhNpwlJPBvZ3egBg25KWzTEAR0HcsDv4L8enqkBMWcNxxTqqOfcHV7lXgtK7jrj-O42nYndg84Ve0B7M9hmxpGo0JA0507NBFdGH_9AiF97CHuDXnJ3HtSVgAIdoGFsB6FFjZcsFM-kwWBFHtXScKm7QXFn5og1aslA66V_uZbgyZU-yZRopbzp9-thiIV1kOlnrVnsbIsqxETa9SNHhD9SjMDA8-OFhgn-m7s2lAief6O0QzP4R2Jg3AC1CHbFg",
        //     "token_type": "bearer",
        //     "expires_in": 1800
        // } as Token;
    }

    private async updateTokenWhenNullOrExpired() {
        var now = moment().subtract(5, 'minutes');
        var expiration = moment().add(token.expires_in, 's');
        if (!token.access_token || expiration.isBefore(now)) {
            await this.auth();
        }
    }
}


export interface SaveConsent {
    logged_user_document: string,
    logged_user_document_type: string,
    nm_logged_user: string,
    institution: string,
    permissions: string[],
    expiration_date_in_months: string,
    authorisation_server_id: string | any
}

export interface Instituicao {
    name: string;
    type: string;
    logoUri: string;
    organizationId: string;
    authorizationUrl: String;
    authorisationServerId: String;
}

export interface Seguradora {
    customerName: string;
    customerDocument: string;
    typePerson: string;
    totalCount: number;
    hasNext: boolean;
    institutions: Instituicao[]
}

export interface Consentimento {
    consentId: string,
    document: string,
    name: string,
    institution: string,
    institutionName: string,
    confirmationDate: string,
    expirationDate: string,
    deadLine: string,
    status: string,
    permissions: string[]
}

export type HidratedConsentimento = Consentimento & {
    permissions: PermissionGroup[]
}

export interface Organizacao {
    organizationId: string,
    organizationName: string,
    document: string,
    description: string,
    participants: string[]
}

export interface Environment {
    REACT_APP_API?: string;
    REACT_APP_DOMAIN?: string;
    REACT_APP_DATA_DOMAIN_SCRIPT?: string;
}

export class ApiService {
    async salvarCompartilhamento(data: SaveConsent): Promise<string> {
        const resp = await genericService.post('/consent', data);
        return resp.data;
    }
    async getSeguradoras(organizationName: string | undefined = undefined, customerDocument: string | undefined = undefined, offset: number = 0, limit: number = 5): Promise<Seguradora> {
        const headers: AxiosRequestHeaders = {}
        if(organizationName)  headers['X-POB-OrganizationName']= encodeURIComponent(organizationName);
        if(customerDocument)  headers['X-POB-CustomerDocument']= encodeURIComponent(customerDocument);
        const { data } = await genericService.getWithHeaders(`/transmitter/receiver?offset=${offset}&limit=${limit}`, headers);
        return data;
    }
    async getPermissoes(type: 'CPF' | 'CNPJ', includeAllPermissions: boolean = false): Promise<SharedDataInterface> {
        const { data } = await genericService.get(`/receiver/permission?documentType=${type}&includeAllPermissions=${includeAllPermissions}`);
        return data;
    }
    async getConsentId(params: string | undefined): Promise<HidratedConsentimento> {
        const { data } = await genericService.get(`/consent/authorized?${sanitizeString(params)}`);
        return data;
    }
    async getConsentIdByConsentId(consentId: string): Promise<Consentimento> {
        const { data } = await genericService.get(`/consent/receiver/${sanitizeString(consentId)}`);
        return data;
    }
    async getOrganization(id: string | undefined): Promise<Organizacao> {
        const { data } = await genericService.get(`/transmitter/receiver/${id}`);
        return data;
    }
    async getOrganizationBy(id: string | undefined,authorisationServerId: string | undefined): Promise<Organizacao> {
        const { data } = await genericService.get(`/transmitter/receiver/${id}?authorisationServerId=${authorisationServerId}`);
        return data;
    }
}

export const sanitizeString = (str: string | undefined) => {
    if (!str) {
        return str;
    }
    str = str.replace(/[^a-z0-9áéíóúñü&= .:,_-]/gim, "");
    return str.trim();
}

export const apiService = new ApiService();
export const genericService = new GenericService();