import { SharedConsent } from "shared/interfaces/shared-consent.interface";

export function listItemsPages(
  items: SharedConsent[] | null,
  pageActual: number,
  limitItems: number,
) {
  if (!items){
    return {
      items: [],
      totalPages: 0,
      page: 0,
    };
  }
  let result: SharedConsent[] = [];
  let totalPage = Math.ceil(items?.length / limitItems);
  let count = pageActual * limitItems - limitItems;
  let delimiter =
    count + limitItems >= items?.length ? items?.length : count + limitItems;

  if (pageActual <= totalPage) {
    for (let i = count; i < delimiter; i++) {
      result.push(items[i]);
      count++;
    }
  }

  return {
    items: result,
    totalPages: totalPage,
    page: pageActual,
  };
}
