import styled from 'styled-components';

export const Container = styled.h3`
	object-fit: contain;
  color: #1a1d1e;
  font-family: "Prudential Modern";
  font-size: 21px;
  font-stretch: normal;
  font-style: normal;
  font-weight: bold;
  letter-spacing: normal;
  line-height: 1.07;
`;
