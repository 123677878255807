import styled, { css } from 'styled-components';

// Interface
interface Props {
	status: boolean;
}

export const Container = styled.div<Props>`
	color: #1a1d1e;
	font-size: 15px;
	overflow: hidden;
	padding-bottom: 20px;
	padding-left: 46px;
	padding-right: 16px;

	${({ status }) =>
		status === false && 
		css`
			height: 0;
			padding-bottom: 0;
		`}

	p {
		padding-bottom: 16px;

		&:last-child {
			padding-bottom: 0;
		}
	}

	ul {
		padding-bottom: 16px;
		list-style: disc;

		li {
			padding-bottom: 6px;

			&:last-child {
				padding-bottom: 0;
			}
		}
	}

	dl {
		padding-bottom: 16px;

		dt {
			padding-bottom: 6px;

			&:last-child {
				padding-bottom: 0;
			}
		}
	}

	table {
		border-collapse: collapse;
		margin: 32px 0;

		tbody {
			tr {
				&:first-child {
					border-bottom: 2px solid #ccc;
					text-align: left;
				}

				th {
					border: 1px solid #ccc;
					border-collapse: collapse;
					font-weight: 700;
					padding: 10px 20px;

					&:first-child {
						width: 25%;
					}
				}

				td {
					border: 1px solid #ccc;
					border-collapse: collapse;
					padding: 10px 20px;
					vertical-align: top;

					&:first-child {
						width: 25%;
					}
				}
			}
		}
	}
`;