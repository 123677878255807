import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

// Styles
import { Container } from './BoxShareInfo.styles';

// Atoms
import ShareText from 'components/atoms/ShareText/ShareText';
import ShareAnchor from 'components/atoms/ShareAnchor/ShareAnchor';

// Interface
interface BoxShareInfoProps {
	id: string;
	statusLabel: string;
	data: string;
	organization: string;
	type: string,
	consentId: string;
}

const BoxShareInfo: React.FC<BoxShareInfoProps> = ({ statusLabel, data, organization, type, consentId }) => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const customerDocument = searchParams?.get("customerDocument");

	return (
		<Container>
			<ShareText>{`Situação: ${statusLabel}`}</ShareText>
			<ShareText>{data}</ShareText>
			<ShareText>{organization}</ShareText>
			<ShareAnchor onClick={() => navigate(`/compartilhamento/${consentId}?tipo=${type}&customerDocument=${customerDocument}`)} data-testid="navigateBTN">Ver detalhes</ShareAnchor>
		</Container>
	);
}

export default BoxShareInfo;