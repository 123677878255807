import React from "react";

// Styles
import { Container } from "./Terms.styles";

// Molecules
import FirstHead from "components/molecules/FirstHead/FirstHead";

// Organisms
import Header from "components/organisms/Header/Header";
import Footer from "components/organisms/Footer/Footer";

// Templates
import StepTerms from "components/templates/step-terms/StepTerms";

const Terms: React.FC = () => {
  return (
    <Container>
      <Header />
      <FirstHead noMobile />
      <StepTerms />
      <Footer />
    </Container>
  );
};

export default Terms;
