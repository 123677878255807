import styled, { css } from "styled-components";

interface Props {
  isOpen: boolean;
}

interface BoxProps {
  type?: "base" | "alternative";
}

export const Container = styled.div<Props>`
  display: none;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9999;

  ${({ isOpen }) =>
    isOpen === true &&
    css`
      display: flex;
    `}
`;

export const Box = styled.div<BoxProps>`
  background-color: #fff;
  border-radius: 6px;
  border: solid 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.5);
  margin: 24px;
  padding: 24px;
  width: 320px;
  z-index: 99999;

  @media (min-width: 768px) {
    width: 640px;
  }

  ${({ type }) =>
    type === "alternative" &&
    css`
      padding: 0;

      @media (max-width: 768px) {
        width: 640px;
      }
    `}
`;

export const TitleBox = styled.div<BoxProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;

  ${({ type }) =>
    type === "alternative" &&
    css`
      border-bottom: 2px solid #cccccc;
      margin-bottom: 0;
      padding: 24px;
    `}
`;

export const Title = styled.h3`
  color: #1b1d1e;
  font-size: 18px;
  font-weight: 700;
  margin-right: 6px;
`;

export const Close = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 18px;
  }
`;

export const Line = styled.hr`
  height: 1px;
  width: calc(100% + 48px);
  border-color: #cccccc;
  margin-left: -24px;
  margin-bottom: 24px;
`;

export const ContentBox = styled.div<BoxProps>`
  ${({ type }) =>
    type === "alternative" &&
    css`
      padding: 24px;
    `}
`;
