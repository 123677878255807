import React from "react";

// Styles
import { Container, BoxIcon, BoxContent, Title } from "./AlertMessage.styles";

// Interface
interface AlertMessageProps {
  type: "warning" | "error" | "success" | "redirect";
  icon: any;
  title?: string;
  content: string;
  noMargin?: boolean;
}

const AlertMessage: React.FC<AlertMessageProps> = ({
  type,
  icon,
  title,
  content,
  noMargin,
}) => {
  return (
    <Container noMargin={noMargin} type={type}>
      <BoxIcon type={type}>{icon}</BoxIcon>
      <BoxContent>
        {title && <Title type={type}>{title}</Title>}
        {content}
      </BoxContent>
    </Container>
  );
};

export default AlertMessage;
