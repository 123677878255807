import styled from "styled-components";

export const Container = styled.div`
  box-shadow: 2px 6px 7px -4px rgba(163, 163, 163, 0.5);
  margin: 24px 16px;

  @media (min-width: 600px) {
    display: flex;
    margin: 24px 0;
  }
`;
