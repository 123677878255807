import React from 'react';

// Styles
import { Container } from './FooterTitle.styles';

// Interface
interface FooterTitleProps {
	children: React.ReactNode;
}

const FooterTitle: React.FC<FooterTitleProps> = ({ children }) => {
	return <Container>{children}</Container>;
}

export default FooterTitle;