import styled from 'styled-components';

export const Container = styled.a`
	color: #0066cc;
	cursor: pointer;
	display: flex;
	align-items: center;
	font-size: 15px;
	font-weight: 400;

	&:hover {
		text-decoration: underline;
	}
`;
