import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";

// Styles
import { Box, Container } from "./StepShares.styles";

// Atoms
import BackButton from "components/atoms/BackButton/BackButton";
import TabsButtons from "components/atoms/TabsButtons/TabsButtons";

// Molecules
import BoxPagination from "components/molecules/BoxPagination/BoxPagination";
import BoxShareInfo from "components/molecules/BoxShareInfo/BoxShareInfo";
import TabsBox from "components/molecules/TabsBox/TabsBox";
import TabsBoxContent from "components/molecules/TabsBoxContent/TabsBoxContent";

// Components
import { AxiosResponse } from "axios";
import Loading from "components/atoms/Loading/Loading";
import ShareTitleBox from "components/organisms/ShareTitleBox/ShareTitleBox";
import { SharedConsent } from "shared/interfaces/shared-consent.interface";
import { GenericService } from "shared/services/generic/GenericService";

import { listItemsPages } from "utils/listItemsPages";

const ifTrueCall = (ifTrue: boolean | null, call: () => void) => {
  if (ifTrue) {
    call();
  }
}

const StepShares: React.FC = () => {
  const [enviadosStatus, setEnviadosStatus] = useState<"active" | "inactive">("inactive");
  const [recebidosStatus, setRecebidosStatus] = useState<"active" | "inactive">("active");
  const [sharedAllDataReceiver, setSharedAllDataReceiver] = useState<SharedConsent[] | null>(null);
  const [sharedDataReceiver, setSharedDataReceiver] = useState<SharedConsent[] | null>(null);
  const [sharedAllDataConsent, setSharedAllDataConsent] = useState<SharedConsent[] | null>(null);
  const [sharedDataConsent, setSharedDataConsent] = useState<SharedConsent[] | null>(null);
  const [consultandoEnviado, setConsultandoEnviado] = useState(false);
  const [consultandoRecebido, setConsultandoRecebido] = useState(false);

  const [cookies, setCookie] = useCookies(["customerDocument"]);
  const customerDocument = cookies.customerDocument;
  const genericService = new GenericService();

  const pageListItems = 12;
  const [consentCurrentPage, setConsentCurrentPage] = useState(1);
  const [consentTotalPages, setConsentTotalPages] = useState<number[] | null>();

  const [receiverCurrentPage, setReceiverCurrentPage] = useState(1);
  const [receiverTotalPages, setReceiverTotalPages] = useState<number[] | null>();

  ifTrueCall(cookies.customerDocument, () => {
    var date = new Date();
    date.setDate(date.getDate() + 10);
    setCookie("customerDocument", cookies.customerDocument, {
      path: "/",
      expires: date,
    });
  });

  function handlePageChange(pageNumber: number, type: "consent" | "receiver") {
    ifTrueCall(type === "consent", () => setConsentCurrentPage(pageNumber));
    ifTrueCall(type === "receiver", () => setReceiverCurrentPage(pageNumber));
  }

  const sharedConsent = () => {
    ifTrueCall(sharedAllDataConsent == null, () => {
      setConsultandoEnviado(true);
  
      genericService
        .get(`/consent/send?document=${encodeURIComponent(cookies.customerDocument)}`)
        .then((response: AxiosResponse) => {
          const data = response.data as SharedConsent[];

          const listItems = listItemsPages(
            data,
            consentCurrentPage,
            pageListItems,
          );
          const pageNumbers = Array.from(
            { length: listItems.totalPages },
            (_, i) => i + 1,
          );

          setSharedAllDataConsent(data);
          setSharedDataConsent(listItems.items);
          setConsentTotalPages(pageNumbers);
        })
        .finally(() => setConsultandoEnviado(false));
    });
  };

  useEffect(() => {
    if (sharedAllDataConsent) {
      const listItems = listItemsPages(
        sharedAllDataConsent,
        consentCurrentPage,
        pageListItems,
      );
      setSharedDataConsent(listItems.items);
    }
  }, [consentCurrentPage]);

  const sharedReceiver = () => {
    ifTrueCall(sharedAllDataReceiver == null, () => {
      setConsultandoRecebido(true);
      genericService
        .get(`/consent/receiver?document=${encodeURIComponent(cookies.customerDocument)}`)
        .then((response: AxiosResponse) => {
          const data = response.data as SharedConsent[];
          const listItems = listItemsPages(
            data,
            receiverCurrentPage,
            pageListItems,
          );
          const pageNumbers = Array.from(
            { length: listItems.totalPages },
            (_, i) => i + 1,
          );

          setSharedAllDataReceiver(data);
          setSharedDataReceiver(listItems.items);
          setReceiverTotalPages(pageNumbers);
        })
        .finally(() => setConsultandoRecebido(false));
    });
  };

  useEffect(() => {
    ifTrueCall(sharedAllDataReceiver !== null && sharedAllDataReceiver !== undefined, () => {
      const listItems = listItemsPages(
        sharedAllDataReceiver,
        receiverCurrentPage,
        pageListItems,
      );

      setSharedDataReceiver(listItems.items);
    });
  }, [receiverCurrentPage]);

  useEffect(sharedReceiver, [customerDocument]);

  return (
    <Container>
      <Box>
        <BackButton noDesktop>Voltar</BackButton>
        <ShareTitleBox title="Meus Compartilhamentos" />
        <TabsBox>
          {/* Botão Recebidos */}
          <TabsButtons
            status={recebidosStatus}
            data-testid="recebidosTab"
            onClick={() => {
              if (!consultandoEnviado) {
                setRecebidosStatus("active");
                setEnviadosStatus("inactive");
                sharedReceiver();
              }
            }}
          >
            Recebidos
          </TabsButtons>

          {/* Botão Enviados */}
          <TabsButtons
            status={enviadosStatus}
            data-testid="enviadosTab"
            onClick={() => {
              setEnviadosStatus("active");
              setRecebidosStatus("inactive");
              sharedConsent();
            }}
          >
            Enviados
          </TabsButtons>
        </TabsBox>

        {/* Box Recebidos */}
        <TabsBoxContent status={recebidosStatus}>
          {recebidosStatus === "active" && consultandoRecebido && (
            <Loading text="Carregando" />
          )}
          {!consultandoRecebido &&
            sharedDataReceiver &&
            sharedDataReceiver.map((content, index) => (
              <BoxShareInfo
                statusLabel={content.statusLabel}
                data={content.data}
                organization={content.organization}
                consentId={content.consentId}
                key={index}
                type="recebidos"
                id={content.consentId}
              />
            ))}
        </TabsBoxContent>
        {receiverTotalPages && (
          <BoxPagination
            status={recebidosStatus}
            totalPages={receiverTotalPages}
            handlePageChange={handlePageChange}
            type={"receiver"}
            currentPage={receiverCurrentPage}
          />
        )}

        {/* Box Enviados */}
        <TabsBoxContent status={enviadosStatus}>
          {enviadosStatus === "active" && consultandoEnviado && (
            <Loading text="Carregando" />
          )}
          {!consultandoEnviado &&
            sharedDataConsent &&
            sharedDataConsent.map((content, index) => (
              <BoxShareInfo
                statusLabel={content.statusLabel}
                data={content.data}
                organization={content.organization}
                consentId={content.consentId}
                key={index}
                type="enviados"
                id={content.consentId}
              />
            ))}
        </TabsBoxContent>
        {consentTotalPages && (
          <BoxPagination
            status={enviadosStatus}
            totalPages={consentTotalPages}
            handlePageChange={handlePageChange}
            type={"consent"}
            currentPage={consentCurrentPage}
          />
        )}
      </Box>
    </Container>
  );
};

export default StepShares;
