import React from 'react';

// Styles
import { Container, StepBox } from './StepBar.styles';

// Components
import StepItem from 'components/atoms/StepItem/StepItem';

// Interface
interface StepBarProps {
	list: any;
}

const StepBar: React.FC<StepBarProps> = ({ list }) => {
	return (
		<Container>
			<StepBox>
				{list && list.map((item: any, key: number) => (
					<StepItem key={key} status={item.status} number={item.number} name={item.name} />
				))}
			</StepBox>
		</Container>
	);
}

export default StepBar;