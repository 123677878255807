import React from 'react';

// Components
import Title from 'components/atoms/Title/Title';
import Text from 'components/atoms/Text/Text';
import TextStrong from 'components/atoms/TextStrong/TextStrong';
import Subtitle from 'components/atoms/Subtitle/Subtitle';

// Styles
import { Container } from './BoxTextContent.styles';

// Interface
interface BoxTextContentProps {
	textContent: string,
	typeContent: 'title' | 'text' | 'textStrong' | 'subtitle';
}

const BoxTextContent: React.FC<BoxTextContentProps> = ({ textContent, typeContent }) => {
	return (
		<Container>
			{typeContent === 'title' && <Title>{textContent}</Title>}
			{typeContent === 'text' && <Text>{textContent}</Text>}
			{typeContent === 'textStrong' && <TextStrong>{textContent}</TextStrong>}
			{typeContent === 'subtitle' && <Subtitle>{textContent}</Subtitle>}
		</Container>
	);
}

export default BoxTextContent;