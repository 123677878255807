import React from 'react';

// Styles
import { Container } from './PostHeader.styles';

// Atoms
import Title from 'components/atoms/Title/Title';

// Components
import BoxPostInformation from 'components/molecules/BoxPostInformation/BoxPostInformation';

// Interface
interface PostHeaderProps {
	sessionTitle: string;
	title: string;
	reading: string;
	date: string;
}

const PostHeader: React.FC<PostHeaderProps> = ({ sessionTitle, title, reading, date }) => {
	return (
		<Container>
			<Title>{sessionTitle}</Title>
			<BoxPostInformation title={title} reading={reading} date={date} />
		</Container>
	);
}

export default PostHeader;