import React from "react";

// Styles
import { Container } from "./ShareInformation.styles";

// Molecules
import FirstHead from "components/molecules/FirstHead/FirstHead";

// Organisms
import Footer from "components/organisms/Footer/Footer";
import Header from "components/organisms/Header/Header";

// Templates
import StepShareInfo from "components/templates/step-shareInfo/StepShareInfo";

const ShareInformation: React.FC = () => {
  return (
    <Container>
      <Header />
      <FirstHead noMobile />
      <StepShareInfo />
      <Footer />
    </Container>
  );
};

export default ShareInformation;
