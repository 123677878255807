import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

// Styles
import {
  Box,
  BoxGroupInformation,
  BoxInformation,
  BoxLinks,
  Container,
  ModalBoxButtons,
} from "./StepShareInfo.styles";

// Atoms
import BackButton from "components/atoms/BackButton/BackButton";
import Button from "components/atoms/Button/Button";
import ShareAnchor from "components/atoms/ShareAnchor/ShareAnchor";
import ShareSeparator from "components/atoms/ShareSeparator/ShareSeparator";
import ShareText from "components/atoms/ShareText/ShareText";
import ShareTitle from "components/atoms/ShareTitle/ShareTitle";
import Text from "components/atoms/Text/Text";
import BoxShareActions from "components/molecules/BoxShareActions/BoxShareActions";
// Molecules
import BoxShareData from "components/molecules/BoxShareData/BoxShareData";
import ModalParticipantes from "components/molecules/ModalParticipantes/ModalParticipantes";
import Modal from "components/organisms/Modal/Modal";
// Organisms
import { AxiosResponse } from "axios";
import Loading from "components/atoms/Loading/Loading";
import ShareTitleBox from "components/organisms/ShareTitleBox/ShareTitleBox";
import { useCookies } from "react-cookie";
import { SharedConsentDetail } from "shared/interfaces/shared-consent-detail.interface";
import {
  ApiService,
  GenericService,
  Organizacao,
} from "shared/services/generic/GenericService";
import { purpose_data_use } from "shared/util/purpose_data_use";
import { isReject } from "shared/util/status_consent";
import BoxPermissions from "components/molecules/BoxPermissions/BoxPermissions";
import { getDocumentType } from "utils/document.functions";

const loadding = (consultando: boolean) => {
  if (!consultando) {
    return null;
  }
  return <Loading text="Carregando" />;
};

const getButtons = (
  consultando: boolean,
  isConsentSend: boolean,
  detail: SharedConsentDetail | null,
  setStopShareModal: any,
  setRenewShareModal: any,
  setEditShareModal: any,
) => {
  if (consultando) {
    return null;
  }
  return (
    <>
      <BoxShareActions title="Ações de compartilhamento">
        <Button
          onClick={() => {
            setStopShareModal(true);
          }}
          data-testid="encerrarBTN"
          layout="action"
          disabled={isReject(
            detail !== undefined && detail !== null
              ? detail.status
              : (null as unknown as string),
          )}
        >
          Revogar
        </Button>
        <Button
          onClick={() => {
            setEditShareModal(true);
          }}
          data-testid="alterarBTN"
          layout="action"
          disabled={
            isConsentSend ||
            isReject(
              detail !== undefined && detail !== null
                ? detail.status
                : (null as unknown as string),
            )
          }
        >
          Alterar
        </Button>
       
      </BoxShareActions>
    </>
  );
};

const getResource = (isConsentSend: boolean) => {
  return isConsentSend ? "send" : "receiver";
};

export const clearCache = (
  set: (name: any, value: string, settings?: any | undefined) => void,
  name: string,
) => {
  set(name, "", {
    path: "/",
    expires: new Date(),
  });
};

export const setCache = (
  set: (name: any, value: string, settings?: any | undefined) => void,
  name: string,
  value: any,
  expires: Date = new Date(),
) => {
  expires.setDate(expires.getDate() + 10);
  set(name, value, {
    path: "/",
    expires,
  });
};

export const renewCache = (
  set: (name: any, value: string, settings?: any | undefined) => void,
  name: string,
  value: any,
  ifTrue: boolean,
) => {
  if (ifTrue) {
    var date = new Date();
    date.setDate(date.getDate() + 10);
    setCache(set, name, value, date);
  }
};

const getData = (
  setShareModal: (value: any) => void,
  consultando: boolean,
  detail: SharedConsentDetail | null,
) => {
  if (consultando) {
    return null;
  }
  return (
    <>
      <BoxGroupInformation>
        <BoxInformation>
          <ShareTitle>Segurado</ShareTitle>
          <ShareText>{detail?.name}</ShareText>
        </BoxInformation>

        <BoxInformation>
          <ShareTitle>{getDocumentType(detail?.document)}</ShareTitle>
          <ShareText>{detail?.document}</ShareText>
        </BoxInformation>

        <BoxInformation>
          <ShareTitle>Finalidade do uso de dados</ShareTitle>
          <ShareText>{purpose_data_use}</ShareText>
        </BoxInformation>

        <BoxInformation>
          <ShareTitle>Instituição selecionada</ShareTitle>
          <ShareText>{detail?.institutionName}</ShareText>
          <ShareAnchor
            href="#"
            onClick={(e) => {
              e.preventDefault();
              setShareModal(true);
            }}
          >
            Ver detalhes do participante
          </ShareAnchor>
        </BoxInformation>

        <BoxInformation>
          <ShareTitle>Data de confirmação</ShareTitle>
          <ShareText>{detail?.confirmationDate}</ShareText>
        </BoxInformation>

        <BoxInformation>
          <ShareTitle>Prazo do compartilhamento</ShareTitle>
          <ShareText>{detail?.deadLine}</ShareText>
        </BoxInformation>

        <BoxInformation>
          <ShareTitle>Situação do compartilhamento</ShareTitle>
          <ShareText>{detail?.statusLabel}</ShareText>
        </BoxInformation>
      </BoxGroupInformation>

      <ShareSeparator />

      <BoxInformation>
        <ShareTitle>Dados compartilhados</ShareTitle>
        <BoxLinks>
          <div>
          <BoxPermissions permissoes={detail?.permissions} />
          </div>
        </BoxLinks>
      </BoxInformation>

      <ShareSeparator />

      <BoxGroupInformation>
        <BoxInformation fullWidth>
          <ShareTitle>Origem dos dados</ShareTitle>
          <ShareTitle>Apólice(s)</ShareTitle>
          {detail &&
            detail.policies &&
            detail.policies.map((content, index) => (
              <ShareText key={index}>{content}</ShareText>
            ))}
          {detail &&
            detail.policiesCollective &&
            detail.policiesCollective.map((content, index) => (
              <ShareText key={index}>{content}</ShareText>
            ))}
        </BoxInformation>
      </BoxGroupInformation>
    </>
  );
};

const StepShareInfo: React.FC = () => {
  const [stopShareModal, setStopShareModal] = useState<boolean>(false);
  const [stop2ShareModal, setStop2ShareModal] = useState<boolean>(false);
  const [editShareModal, setEditShareModal] = useState<boolean>(false);
  const [renewShareModal, setRenewShareModal] = useState<boolean>(false);
  const [detail, setDetail] = useState<SharedConsentDetail | null>(null);
  const [carregando, setCarregando] = useState<boolean>(false);
  const [consultando, setConsultando] = useState<boolean>(true);

  const [shareModal, setShareModal] = useState<boolean>(false);
  const [organization, setOrganization] = useState<Organizacao>();

  // Navegação
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies([
    "customerDocument",
    "consentIdForChange",
    "consentRenew",
  ]);

  const [searchParams] = useSearchParams();
  const shareId = useParams().shareId;
  const consentType = searchParams?.get("tipo");
  const isConsentSend = consentType === "enviados";

  const genericService = new GenericService();
  const apiService = new ApiService();

  renewCache(
    setCookie,
    "customerDocument",
    cookies.customerDocument,
    cookies.customerDocument,
  );

  let closeModal = () => {
    setStop2ShareModal(true);
    setStopShareModal(false);
  };

  let closeConsent = () => {
    setCarregando(true);
    let path = isConsentSend
      ? `/transmitter/consent/${shareId}`
      : `/consent/${shareId}/document/${window.btoa(cookies.customerDocument)}`;
    genericService
      .delete(path)
      .then(() => {
        closeModal();
      })
      .finally(() => {
        setCarregando(false);
      });
  };

  let renewConsent = () => {
    setCarregando(true);
    renovarConsentimento();
    return;
  };

  useEffect(() => {
    let sharedDetail = () =>
      genericService
        .get(`/consent/${getResource(isConsentSend)}/${shareId}`)
        .then((response: AxiosResponse) => {
          const data = response.data as SharedConsentDetail;
          setDetail(data);
          apiService
            .getOrganizationBy(data.institution,data.authorisationServerId)
            .then((org: Organizacao) => {
              setOrganization(org);
            })
            .finally(() => {
              setConsultando(false);
            });
        });

    sharedDetail();
  }, [isConsentSend, shareId]);

  const alterarConsentimento = () => {
    var date = new Date();
    date.setDate(date.getDate() + 10);
    clearCache(setCookie, "consentRenew");
    setCache(setCookie, "consentIdForChange", btoa(shareId as string));
    navigate(`/home`);
  };

  const renovarConsentimento = () => {
    clearCache(setCookie, "consentIdForChange");
    setCache(setCookie, "consentRenew", btoa(shareId as string));
    navigate(`/home?renovar=true`);
  };

  return (
    <Container>
      <ModalParticipantes
        organization={organization}
        setShareModal={setShareModal}
        shareModal={shareModal}
      />
      <Box>
        <BackButton noDesktop>Voltar</BackButton>
        <ShareTitleBox title="Resumo da solicitação" />
        <BoxShareData>
          {loadding(consultando)}
          {getData(setShareModal, consultando, detail)}
        </BoxShareData>

        {getButtons(
          consultando,
          isConsentSend,
          detail,
          setStopShareModal,
          setRenewShareModal,
          setEditShareModal,
        )}
      </Box>
      <Modal
        title="Renovar Compartilhamento"
        titleLine={true}
        showModal={renewShareModal}
        setShowModal={setRenewShareModal}
      >
        <Text>
          Na renovação você cria um novo compartilhamento baseado nos seus dados
          atuais, sem alterá-los.
        </Text>
        <ModalBoxButtons>
          {carregando ? (
            <Loading text="Renovando" />
          ) : (
            <>
              <Button
                data-testid="renewShareBTN"
                onClick={() => renewConsent()}
                layout="base"
                modal
                modalLastOrder
              >
                Renovar compartilhamento
              </Button>
              <Button
                data-testid="closeRenewShareBTN"
                spacing="small"
                onClick={() => {
                  setRenewShareModal(false);
                }}
                layout="inverse"
                modal
              >
                Sair
              </Button>
            </>
          )}
        </ModalBoxButtons>
      </Modal>
      <Modal
        title="Você está alterando um compartilhamento"
        titleLine={true}
        showModal={editShareModal}
        setShowModal={setEditShareModal}
      >
        <Text>
          O processo de alteração é irreversível e encerra o compartilhamento
          atual. Na alteração você cria um novo compartilhamento para esta
          instituição, reaproveitando seus dados.
        </Text>
        <ModalBoxButtons>
          <Button
            data-testid="editShareBTN"
            onClick={alterarConsentimento}
            layout="base"
            modal
            modalLastOrder
          >
            Alterar compartilhamento
          </Button>
          <Button
            data-testid="closeEditShareBTN"
            spacing="small"
            onClick={() => {
              setEditShareModal(false);
            }}
            layout="inverse"
            modal
          >
            Sair
          </Button>
        </ModalBoxButtons>
      </Modal>
      <Modal
        title={
          consentType === "enviados"
            ? "Tem certeza que deseja encerrar compartilhamento?"
            : "Tem certeza que deseja desistir do compartilhamento?"
        }
        titleLine={true}
        showModal={stopShareModal}
        setShowModal={setStopShareModal}
      >
        <Text>
          O processo de encerramento do compartilhamento de dados é uma ação
          irreversível, podendo existir a possibilidade de que você perca alguma
          funcionalidade que só existem por causa dele.
        </Text>
        <ModalBoxButtons>
          {carregando ? (
            <Loading text="Encerrando" />
          ) : (
            <>
              <Button
                data-testid="stopShareBTN"
                onClick={() => {
                  closeConsent();
                }}
                layout="base"
                modal
                modalLastOrder
              >
                Encerrar compartilhamento
              </Button>
              <Button
                data-testid="closeStopShareBTN"
                spacing="small"
                onClick={() => {
                  setStopShareModal(false);
                }}
                layout="inverse"
                modal
              >
                Sair
              </Button>
            </>
          )}
        </ModalBoxButtons>
      </Modal>
      <Modal
        title="Compartilhamento de dados encerrado com sucesso"
        titleLine={true}
        showModal={stop2ShareModal}
        setShowModal={setStop2ShareModal}
      >
        <Text>
          O encerramento está relacionado apenas com esse compartilhamento dos
          seus dados, não os excluindo da base de dados da instituição.
        </Text>
        <Button
          data-testid="exitShare"
          onClick={() => {
            setStop2ShareModal(false);
            navigate(`/meus-compartilhamentos`);
          }}
          layout="inverse"
        >
          Sair
        </Button>
      </Modal>
    </Container>
  );
};

export default StepShareInfo;
