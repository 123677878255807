import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
  margin: 40px 0;
  width: 100%;
`;

export const Text = styled.p`
  color: #1a1d1e;
  font-size: 12px;
`;

export const BoxEllipsis = styled.div`
  display: inline-block;
  height: 12px;
  width: 80px;
  position: relative;
`;

export const Ellipsi = styled.div`
  position: absolute;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
  background: #676767;
  border-radius: 50%;
  height: 12px;
  width: 12px;

  &:nth-child(1) {
    left: 8px;
    animation: ellipsis1 0.6s infinite;
  }

  &:nth-child(2) {
    left: 8px;
    animation: ellipsis2 0.6s infinite;
  }

  &:nth-child(3) {
    left: 32px;
    animation: ellipsis2 0.6s infinite;
  }

  &:nth-child(4) {
    left: 56px;
    animation: ellipsis3 0.6s infinite;
  }

  @keyframes ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }

  @keyframes ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
`;
