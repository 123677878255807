import styled, { css } from 'styled-components';

// Interface
interface Props {
	type?: 'flag' | 'text';
}

export const Container = styled.span<Props>`
	background-color: #999;
	border: none;
	border-radius: 2px;
	color: #ffffff;
	font-size: 11.3px;
	font-weight: bold;
	padding: 2px 6px;
	text-transform: uppercase;

	&:first-child {
		margin-right: 6px;
	}

	${({ type }) =>
		type === 'text' &&
		css`
			background-color: transparent;
			color: #666666;
			font-size: 15px;
			font-weight: normal;
			padding: 0;
			text-transform: none;
		`}
`;
