import React from 'react';

// Styles
import { Container } from './PostFlag.styles';

// Interface
interface PostFlagProps {
	children: React.ReactNode;
	type?: 'flag' | 'text'
}

const PostFlag: React.FC<PostFlagProps> = ({ children, type }) => {
	return (
		<Container type={type}>{children}</Container>
	);
}

export default PostFlag;