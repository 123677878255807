import React, { Fragment } from 'react';

// Styles
import {
	Container,
	AccordionBox
} from './BoxPostAccordion.styles';

// Components
import Accordion from '../Accordion/Accordion';

const BoxPostAccordion: React.FC = () => {
	return (
		<Container>
			<AccordionBox>
				<Accordion isOpen title='1. Coleta de dados pessoais' content={<Fragment>
					<p>Para que possamos prestar os nossos serviços, precisamos coletar algumas informações sobre você. Tais informações poderão ser coletadas quando inseridas ou submetidas voluntariamente por você̂, seja ao utilizar o(s) Site(s), aplicativos e serviços oferecidos pela <strong>PRUDENTIAL DO BRASIL</strong>, ou quando houver, por exemplo, a criação de Conta de Acesso e utilização das ferramentas e soluções da <strong>PRUDENTIAL DO BRASIL</strong>, ou durante o seu relacionamento conosco, desde a cotação de um seguro, até́ a regularização de um sinistro e pagamento da indenização.</p>
					<p>É possível, em casos específicos, que sejam coletados Dados Pessoais por meio de Terceiros, sejam corretores, estipulantes, empresas de análise de crédito, parceiros ou prestadores de serviços, sendo todos eles fontes legítimas e previamente submetidas aos processos de aprovação interna. A exemplo, a <strong>PRUDENTIAL DO BRASIL</strong> poderá́ contar com prestadores de serviços para, por exemplo, validar as suas informações e mantê-las atualizadas, para cumprimento das obrigações decorrentes do contrato com você, bem como para fins de prevenção à lavagem de dinheiro e prevenção à fraude – visando a segurança dos próprios segurados e beneficiários.</p>
					<p>São exemplos de informações que a <strong>PRUDENTIAL DO BRASIL</strong> poderá́ coletar:</p>
					<dl>
						<dt>(i) nome completo;</dt>
						<dt>(ii) sexo;</dt>
						<dt>(iii) CPF;</dt>
						<dt>(iv) estado civil;</dt>
						<dt>(v) filiação;</dt>
						<dt>(vi) renda anual;</dt>
						<dt>(vii) profissão;</dt>
						<dt>(viii) endereço de e-mail;</dt>
						<dt>(ix) endereço postal;</dt>
						<dt>(x) números de telefone e gravações (quando houver contato com você);</dt>
						<dt>(xi) data de nascimento;</dt>
						<dt>(xii) informações de pagamento (incluindo informações sobre o cartão utilizado);</dt>
						<dt>(xiii) informações sobre o navegador e o sistema operacional do dispositivo de acesso ao Site;</dt>
						<dt>(xiv) endereço IP;</dt>
						<dt>(xv) páginas da Web visitadas, incluindo, mas não se limitando a, os sites de origem da navegação;</dt>
						<dt>(xvi) links e botões clicados;</dt>
						<dt>(xvii) dados de saúde e histórico médico; e</dt>
						<dt>(xviii) dados patrimoniais. </dt>
					</dl>
					<p>Caso você acesse nossa plataforma, coletaremos automaticamente as seguintes informações durante a sua navegação: características do dispositivo de acesso, do navegador, IP de forma anonimizada (com data e hora) e origem do IP. Além disso, você̂ poderá́ permitir a coleta de informações sobre cliques e páginas acessadas, por meio de cookies, a fim de proporcionar uma experiência de navegação personalizada, apresentar publicidade, medir a eficácia de uma campanha publicitária lançada, medir o tempo de visita ao Site e quaisquer problemas encontrados, como mensagens de erro no sistema.</p>
					<table>
						<tbody>
							<tr>
								<th>
									Tipos de cookies
								</th>
								<th>
									O que eles fazem?
								</th>
							</tr>
							<tr>
								<td>
									<p>Necessários</p>

								</td>
								<td>
									<p>Os <i>cookies</i> necessários são essenciais para que o Site da <b>PRUDENTIAL DO BRASIL</b> carregue adequadamente e permitam que você navegue corretamente. </p>

								</td>
							</tr>
							<tr>
								<td>
									<p>Desempenho</p>

								</td>
								<td>
									<p>Os <i>cookies</i> de desempenho nos ajudam a entender como os visitantes interagem com as páginas da <b>PRUDENTIAL DO BRASIL</b>, fornecendo informações sobre as áreas visitadas, o tempo de visita ao Site e quaisquer problemas encontrados, como mensagens de erro.</p>

								</td>
							</tr>
							<tr>
								<td>
									<p>Funcionais</p>

								</td>
								<td>
									<p>Os <i>cookies</i> funcionais permitem que as páginas da <b>PRUDENTIAL DO BRASIL</b> se lembrem de suas escolhas, para proporcionar uma experiência personalizada.</p>

								</td>
							</tr>
							<tr>
								<td>
									<p>Marketing</p>

								</td>
								<td>
									<p>Os <i>cookies</i> para fins de marketing são utilizados para fornecer mais conteúdo relevante a você. Podem ser utilizados para apresentar publicidade e permitem a medição da eficácia de campanhas publicitárias.</p>

								</td>
							</tr>
						</tbody>
					</table>
					<p>Ao acessar, pela primeira vez, o Site da <strong>PRUDENTIAL DO BRASIL</strong> e selecionar “Estou de acordo”, você̂ aceita os cookies acima para otimizar o funcionamento do Site. Alguns desses cookies são utilizados para melhorar a sua experiência, nos ajudando a entender como você̂ interage com o Site, personalizando conteúdos e anúncios. Você pode, em qualquer momento, excluir ou desativar os cookies, acessando as configurações do seu navegador ou do sistema operacional do seu dispositivo móvel. Todos os cookies podem ser desativados, exceto os cookies necessários.</p>
					<p>Para toda a coleta de Dados Pessoais, a <strong>PRUDENTIAL DO BRASIL</strong> sempre seguirá as seguintes regras:</p>
					<ul>
						<li>Apenas serão coletadas informações imprescindíveis para a prestação dos serviços oferecidos.</li>
						<li>Caso seja necessária a coleta de outros Dados Pessoais, tomaremos o devido cuidado de avisá-lo sobre tal ação e coletar a sua autorização, sempre que necessário.</li>
						<li>Qualquer autorização que coletarmos de você será acompanhada do devido grau de informação.</li>
						<li>Os Dados Pessoais coletados somente serão utilizados para cumprir com as Finalidades informadas a você.</li>
					</ul>
					<p>A <strong>PRUDENTIAL DO BRASIL</strong> possui parceiros comerciais que podem oferecer serviços por meio de funcionalidades ou sites acessados a partir dos sites, aplicativos e serviços oferecidos pela <strong>PRUDENTIAL DO BRASIL.</strong> Os dados fornecidos por você̂ a estes parceiros serão de responsabilidade destes, estando assim sujeitos às suas próprias práticas de obtenção e uso de dados, sem que caiba qualquer ônus à <strong>PRUDENTIAL DO BRASIL</strong> pelo Tratamento de tais informações. Apesar disso, a <strong>PRUDENTIAL DO BRASIL</strong> empenha seus melhores esforços para relacionar-se com parceiros comerciais que atendam níveis adequados de proteção de dados.</p>
				</Fragment>} />
				<Accordion title='2. Como utilizamos os dados pessoais' content={<Fragment>
					<p>Os Dados Pessoais tratados pela <strong>PRUDENTIAL DO BRASIL</strong> poderão ser utilizados para determinadas Finalidades, estando as principais hipóteses listadas abaixo:</p>
					<ul>
						<li>Execução de contratos ou procedimentos preliminares<br/> Nós utilizaremos os dados que você̂ nos forneceu para cumprir a Finalidade informada no momento da coleta. Podemos usar os Dados Pessoais necessários, para permitir a elaboração e execução do contrato com você̂, possibilitando, por exemplo, fazer consultas preliminares, realizar uma cotação e preparar uma proposta de seguro, processar um sinistro, pagar o valor do prêmio, prestar assistências e coberturas previstas em produtos, incluindo a oferta de benefícios disponibilizados via aplicativo para gestão de saúde e promoção do bem estar, atender adequadamente às solicitações e dúvidas, bem como cumprir com as demais responsabilidades previstas no contrato e apólice de seguro. </li>
						<li>Cumprimento de obrigação legal ou regulatória<br/>Seus Dados Pessoais poderão ser utilizados para atender obrigações decorrentes de leis, normas e regulações setoriais aplicáveis de órgãos fiscalizadores e reguladores, como a SUSEP e ANPD, bem como perante o Poder Judiciário e/ou outra autoridade competente, podendo ser utilizados, por exemplo, para colaborar e/ou cumprir com ordem judicial ou requisição por autoridade administrativa. Este Tratamento poderá́ incluir seus dados de identificação, documentos pessoais e dados bancários, por exemplo, quando do envio das comunicações obrigatórias à Receita Federal. Nestes casos, nos certificaremos que apenas os dados estritamente necessários sejam utilizados e compartilhados.</li>
						<li>Mediante Consentimento  do Titular dos Dados Pessoais<br/>Quando for necessário o consentimento do titular de dados pessoais, a <strong>PRUDENTIAL DO BRASIL</strong> pedirá explicitamente para as finalidades específicas de uma determinada atividade de tratamento, bem como informará ao titular de dados pessoais sobre a revogação do consentimento.</li>
						<li>Exercício regular de direitos da <strong>PRUDENTIAL DO BRASIL</strong><br/>Mesmo após o término da relação contratual, nós poderemos tratar os Dados Pessoais necessários para exercer ou defender nossos direitos, por exemplo, como prova em processos judiciais, administrativos ou arbitrais, na condução dos processos de recebimento de reclamações em Ouvidorias e no recebimento e elaboração de respostas a reclamações apresentadas às entidades que fiscalizam as atividades da <strong>PRUDENTIAL DO BRASIL</strong>.</li>
						<li>Para interesses legítimos da <strong>PRUDENTIAL DO BRASIL</strong><br/>Nos casos admitidos pela Lei Geral de Proteção de Dados Pessoais - LGPD (Lei 13.709/18), poderemos tratar Dados Pessoais para finalidades legítimas envolvendo a continuidade de nossas operações – por exemplo: na construção e avaliação do perfil dos segurados para proporcionar uma experiência personalizada na fruição de nossos produtos e serviços contratados e serviços assistenciais correlacionados; para promoção dos serviços da <strong>PRUDENTIAL DO BRASIL</strong>, franquias e parceiros, proporcionando informações sobre novidades, funcionalidades, conteúdos, notícias e demais informações relevantes para a manutenção do relacionamento com a <strong>PRUDENTIAL DO BRASIL</strong>; no aperfeiçoamento do uso e experiência dos sites e, aplicativos da <strong>PRUDENTIAL DO BRASIL</strong>; na realização de estudos, pesquisas e levantamentos pertinentes aos serviços prestados pela <strong>PRUDENTIAL DO BRASIL</strong>, para permitir uma melhor distribuição e alocação de recursos internos ou, ainda, para mensurar a qualidade de nosso atendimento e seu nível de satisfação com nossos serviços.<br/>Nessas hipóteses, a <strong>PRUDENTIAL DO BRASIL</strong> observará os limites da sua expectativa e nunca atuará em prejuízo de seus interesses, direitos e liberdades fundamentais.</li>
						<li>Para prevenção à fraude<br/>Eventualmente, quando estritamente necessário, poderemos tratar os Dados Pessoais necessários para comprovar sua identidade e evitar fraudes, como na realização de investigações para confirmar as informações fornecidas quando da abertura de sinistros, inclusive, infrações relacionadas ao uso dos sites e aplicativos da <strong>PRUDENTIAL DO BRASIL</strong>.</li>
					</ul>
				</Fragment>} />
				<Accordion title='3. Como os dados são compartilhados' content={<>
					<p>Para que seja possível operacionalizar os seus serviços, em algumas hipóteses, a <b>PRUDENTIAL DO BRASIL</b> poderá compartilhar os Dados Pessoais necessários. São exemplos de situações em que esse compartilhamento poderá ocorrer:</p>
					<ul>
						<li>Com empresas parceiras, fornecedores e empresas do Grupo Econômico PRUDENTIAL, quando necessário às atividades comerciais e à prestação dos serviços contratados, desde que devidamente aprovadas pelos respectivos processos de segurança da&nbsp;<b>PRUDENTIAL DO BRASIL</b>;</li>
						<li>Com autoridades, órgãos reguladores, entidades governamentais ou outros Terceiros, para a proteção dos interesses da&nbsp;<b>PRUDENTIAL DO BRASIL</b>, em qualquer tipo de conflito, incluindo ações judiciais e processos administrativos;</li>
						<li>No caso de transações e alterações societárias envolvendo a&nbsp;<b>PRUDENTIAL DO BRASIL</b>, hipótese em que a transferência das informações será necessária para a continuidade dos serviços;</li>
						<li>Mediante obrigações legais, regulatórias, contratuais, ordem judicial ou pelo requerimento de autoridades administrativas que detenham competência legal para a sua requisição;</li>
						<li>Com empresas dos ramos de resseguro e cosseguro;</li>
						<li>Com empresas de serviço de processamento financeiro e contábil;</li>
						<li>Com prestadores de serviços médicos (profissionais de saúde, serviços de saúde);</li>
						<li>Para o exercício regular de direitos e/ou para sua proteção.</li>
					</ul>
					<p>Quando houver o compartilhamento dos seus dados, isso acontecerá para cumprir alguma finalidade específica, informada a você, e a&nbsp;<b>PRUDENTIAL DO BRASIL</b>&nbsp;adotará as medidas necessárias para assegurar que qualquer Terceiro, que receba os seus Dados Pessoais, garanta a eles a proteção adequada.</p>
					<p>É possível que os dados sejam compartilhados com algumas de nossas empresas do Grupo Econômico&nbsp;<b>PRUDENTIAL</b>, inclusive com a nossa matriz, podendo estar localizadas no exterior. Nestes casos, nos certificaremos de que essa transferência ocorra mediante as garantias necessárias de proteção aos seus Dados Pessoais.</p>
				</>} />
				<Accordion title='4. Como mantemos os dados seguros' content={<>
					<p>A <b>PRUDENTIAL DO BRASIL</b> possui políticas e procedimentos internos que tem por objetivo preservar a sua privacidade, garantindo a segurança e a proteção dos seus Dados Pessoais. Nesse contexto, adotamos medidas de segurança, técnicas e administrativas, aptas a manter os seus Dados Pessoais seguros e protegidos de acessos não autorizados e de situações acidentais ou ilícitas de destruição, perda, alteração, comunicação ou qualquer outra forma de Tratamento inadequado ou ilícito, sempre à luz das regras aplicáveis de proteção de dados e segurança da informação.</p>
					<p>Qualquer Dado Pessoal sob o controle da&nbsp;<b>PRUDENTIAL DO BRASIL</b>&nbsp;será armazenado conforme medidas de segurança adotadas que incluem:</p>
					<ul>
						<li>Proteção contra acesso não autorizado a seus sistemas;</li>
						<li>Acesso restrito de pessoas específicas ao local onde são armazenadas as suas informações pessoais;</li>
						<li>Renovação do&nbsp;<i>backup</i>&nbsp;de informações, diariamente.</li>
					</ul>
					<p>Embora a&nbsp;<b>PRUDENTIAL DO BRASIL</b>&nbsp;adote os melhores esforços para preservar a sua privacidade e proteger os seus Dados Pessoais, nenhuma transmissão de informações é totalmente segura, estando sempre suscetíveis a ocorrência de falhas técnicas, vírus ou ações similares.</p>
					<p>Na remota hipótese de incidência de episódios desta natureza, a&nbsp;<b>PRUDENTIAL DO BRASIL</b>&nbsp;garantirá a adoção de todas as medidas cabíveis para remediar as consequências do evento, sempre garantindo a devida e cabível transparência a você.</p>
				</>} />
				<Accordion title='5. Transferência internacional de dados' content={<>
					<p>Os Dados Pessoais tratados poderão ser armazenados em servidor próprio da&nbsp;<b>PRUDENTIAL DO BRASIL</b>&nbsp;ou de Terceiros contratados para esse fim, sejam eles alocados no Brasil ou no exterior, podendo ainda ser armazenados por meios de tecnologia de <i>cloud computing</i> (computação em nuvem) e/ou outras que surjam futuramente, visando sempre a melhoria e o aperfeiçoamento das atividades da&nbsp;<b>PRUDENTIAL DO BRASIL</b>.</p>
					<p>A <b>PRUDENTIAL DO BRASIL</b>&nbsp;irá eliminar ou anonimizar os Dados Pessoais tratados quando atingidos os fins para os quais foram coletados, ou quando você solicitar a sua eliminação, após o decurso do prazo de cumprimento de obrigação legal ou regulatória, ou demais hipóteses previstas na legislação aplicável que determinem a retenção das informações e dados.</p>
				</>} />
				<Accordion title='6. Quais são os seus direitos' content={<>
					<p>Você tem os seguintes direitos relativos aos seus Dados Pessoais:</p>
					<ul>
						<li>Saber se tratamos algum Dado Pessoal seu;</li>
						<li>Saber quais Dados Pessoais seus são tratados por nós;</li>
						<li>Corrigir dados incompletos, inexatos ou desatualizados, pelos meios exigidos pela regulamentação específica, quando necessário;</li>
						<li>Solicitar a anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou que, porventura, tenham sido tratados em desconformidade com a lei;</li>
						<li>Solicitar a portabilidade dos dados a outro fornecedor de serviço ou produto, caso isso seja feito expressamente;</li>
						<li>Solicitar a eliminação dos dados tratados com o seu Consentimento;</li>
						<li>Obter informações sobre as entidades públicas ou privadas com as quais compartilhamos os seus dados;</li>
						<li>Quando a atividade de Tratamento necessitar do seu Consentimento, você pode se negar a consentir. Nessa hipótese, caso você negue o Consentimento ou nos solicite a revogação de um Consentimento previamente realizado, lhe informaremos sobre as consequências da não realização de tal atividade;</li>
						<li>Quando a atividade de Tratamento necessitar do seu Consentimento, a qualquer momento você poderá revogá-lo.</li>
					</ul>
					<p>Neste ponto, todas as requisições serão:</p>
					<ul>
						<li>Oportunizadas de forma gratuita;</li>
						<li>Submetidas a uma forma de validação de sua identidade (a fim de que a&nbsp;<b>PRUDENTIAL DO BRASIL</b>&nbsp;direcione o atendimento de requisições, exclusivamente ao Titular, visando a sua segurança e proteção de seus dados).</li>
					</ul>
					<p>Para exercer os seus direitos como Titular dos Dados Pessoais, acesse os canais conforme abaixo:</p>
					<p><b>Clientes - Seguros vinculados ao estipulante Mercado Pago</b><br/>3003 9230 – capitais e regiões metropolitanas<br/>0800 799 9805 – demais localidades<br/>0800 770 0559 – Em caso de deficiência auditiva ou de fala<br/>Atendimento 24 horas</p>
					<p><b>Clientes - Prudential do Brasil Vida Individual <br/>Prudential Responde</b><br/>3003 7783 – capitais e regiões metropolitanas<br/>0800 200 7783 – demais localidades<br/>De segunda a sexta-feira, das 8h às 20h (exceto feriados)</p>
					<p><b>Clientes - Prudential do Brasil Vida em Grupo</b><br/>0800 730 0012<br/>Atendimento 24 horas</p>
					<p>0800 730 0013<br/>Em caso de deficiência auditiva ou de fala. Atendimento 24 horas</p>
				</>} />
				<Accordion title='7. Disposições gerais' content={<>
					<p>O teor desta Política de Privacidade poderá ser atualizado ou modificado a qualquer momento, para garantir o nosso compromisso de transparência com você.</p>
					<p>Nessas hipóteses, a&nbsp;<b>PRUDENTIAL DO BRASIL</b>&nbsp;vai disponibilizar, com a data de atualização, a política de privacidade no link&nbsp;<a href="https://www.prudential.com.br/politica-de-privacidade.html">https://www.prudential.com.br/politica-de-privacidade.html</a> disponível nos Sites, aplicativos e serviços prestados pela <b>PRUDENTIAL DO BRASIL,</b>&nbsp;utilizados por você.</p>
					<p>Em caso de dúvidas com relação às disposições constantes desta Política de Privacidade ou sobre o Tratamento dos seus Dados Pessoais pela&nbsp;<b>PRUDENTIAL DO BRASIL</b>, entre em contato com a nossa Encarregada pelo Tratamento de Dados Pessoais, Sabrina Calixto, pelo e-mail <a href="mailto:dpo.brasil@prudential.com">dpo.brasil@prudential.com</a>, acesse um dos nossos canais de atendimento já informados acima ou pelo link&nbsp;<a href="https://www.prudential.com.br/duvidas/canais-de-atendimento.html">https://www.prudential.com.br/duvidas/canais-de-atendimento.html</a>.</p>
				</>} />
				<Accordion title='8. Leia aplicável e jurisdição' content={<>
					<p>A presente Política de Privacidade da Prudential do Brasil será interpretada segundo a legislação brasileira, no idioma português, sendo eleito o foro de domicílio do Titular para dirimir qualquer litígio ou controvérsia envolvendo o presente documento, salvo ressalva específica de competência pessoal, territorial ou funcional pela legislação aplicável.</p>
				</>} />
				<Accordion title='Glossário' content={<>
					<p>Para os fins deste documento, devem se considerar as seguintes definições e descrições para seu melhor entendimento:</p>
					<p><b>PRUDENTIAL DO BRASIL</b>: PRUDENTIAL DO BRASIL SEGUROS S.A., pessoa jurídica de direito privado, inscrita no CNPJ sob o no 21.986.074/0001-19, com sede na Av. República do Chile, 330 Torre Leste - 24º e 25º andares - Centro, Rio de Janeiro - RJ, 20031-170. OU PRUDENTIAL DO BRASIL VIDA EM GRUPO S.A., pessoa jurídica de direito privado, inscrita no CNPJ sob o no 21.986.074/0001-19, com sede na Avenida Paulista, no 2064, 7o pavimento, Edifício Vipasa, CEP: 013.10-200, São Paulo/SP.</p>
					<p><b><i>Cloud computing</i> ou computação em nuvem:</b>&nbsp; Tecnologia de virtualização de serviços construída a partir da interligação de mais de um servidor por meio de uma rede de informação comum (p.ex. a Internet), com o objetivo de reduzir custos e aumentar a disponibilidade dos serviços sustentados.</p>
					<p><b>Conta de Acesso:&nbsp;</b>Credencial de um usuário que pode ser necessária para utilizar ou acessar área restrita e funcionalidades exclusivas do portal.</p>
					<p><b>Consentimento:&nbsp;</b>Manifestação livre, informada e inequívoca do Titular que autoriza o Tratamento dos seus Dados Pessoais para uma Finalidade específica.</p>
					<p><b>Dado Pessoal:</b>&nbsp;Qualquer informação relacionada a pessoa natural, direta ou indiretamente, identificada ou identificável.&nbsp;</p>
					<p><b>Encarregado</b>: pessoa indicada por nós para ser o responsável por garantir o atendimento aos seus direitos e esclarecer dúvidas sobre o Tratamento de seus Dados Pessoais.</p>
					<p><b>Finalidade</b>: Motivo pelo qual o Dado Pessoal será tratado, ou objetivo que se pretende atingir com o Tratamento dos dados.</p>
					<p><b>Grupo Econômico PRUDENTIAL</b>: Empresas integrantes, afiliadas e subsidiárias do grupo econômico ao qual a&nbsp;<b>PRUDENTIAL DO BRASIL</b>&nbsp;pertence.</p>
					<p><b>IP</b>: Abreviatura de “Internet Protocol”. É o conjunto alfanumérico que identifica os dispositivos dos&nbsp;<b>USUÁRIOS</b>&nbsp;na Internet.&nbsp;</p>
					<p><b>Terceiro</b>: Refere-se, mas não está limitado, a toda e qualquer pessoa física ou jurídica, que a empresa se relacione ou venha a se relacionar, prestador de serviços, fornecedor, consultor, cliente, parceiro de negócio, terceiro contratado ou subcontratado, locatário, cessionário de espaço comercial, independentemente de contrato formal ou não, incluindo aquele que utiliza o nome da empresa para qualquer fim ou que presta serviços, fornece materiais, interage com Funcionário Público, com o Governo ou com outros Terceiros em nome da Empresa.</p>
					<p><b>Titular</b>: Pessoa natural a quem se referem os Dados Pessoais.</p>
					<p><b>Tratamento</b>: Toda operação realizada com Dados Pessoais dentro de seu ciclo de vida, como coleta, produção, recepção, classificação, utilização, acesso, reprodução, transmissão, distribuição, processamento, arquivamento, armazenamento, eliminação, avaliação ou controle da informação, modificação, comunicação, transferência, difusão ou extração.</p>
				</>} />
			</AccordionBox>
		</Container>
	);
}

export default BoxPostAccordion;