import styled, { css } from "styled-components";

interface Props {
  status: "active" | "inactive";
}

export const Container = styled.div<Props>`
  ${({ status }) =>
    status === "active"
      ? css`
          display: flex;
        `
      : css`
          display: none;
        `}

  justify-content: center;
  align-items: center;
  gap: 8px 4px;
  flex-wrap: wrap;
  margin-bottom: 24px;
  padding: 0 8px;

  @media (min-width: 600px) {
    gap: 16px 8px;
    padding: 0 16px;
  }
`;
