import styled from "styled-components";

export const Container = styled.div`
  .listboxTitle {
    font-size: 15px;
    font-weight: 400;
    color: #1a1d1e;
  }
`;

export const h1 = styled.h1`
  object-fit: contain;
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  letter-spacing: normal;
  color: #333;
`;
export const Button = styled.button`
  background-color: #07639d;
  border-radius: 3px;
  border: solid 1px #07639d;
  color: #fff;
  display: block;
  font-size: 15px;
  height: 45px;
  margin: 0 auto;
  text-align: center;
  width: 232px;
  
  &:disabled {
    background-color: #afafaf;
    color: #999999;
    border: 1px solid #afafaf
  }
;
`;

export const BoxInfo = styled.div`
  width: auto;
  height: auto;
  min-height: 54px;
  background: #F6F7F7 0% 0% no-repeat padding-box;
  border-radius: 4px;
  margin-left: 18px;
	margin-right: 18px;
  p{
    color: #555555;
    padding: 15px;
    font-size: 13px;
    line-height: 24px;
    a{
      color: #07639D;
      cursor: pointer;
    }
  }
`;

export const LabelInfo = styled.label`
  a{
    color: #0066cc;
  }
`
export const AccordionBox = styled.div`
	margin-top: 20px;
  margin-left: 15px;
	margin-right: 15px;
`;