import styled from 'styled-components';

export const Container = styled.div`
	background-color:#E5E7EB;
	display: flex;
	align-items: center;
	height: 2px;
	justify-content: start;
	margin: 24px 18px 64px;
`;

export const StepBox = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
`;
