import styled from 'styled-components';

export const Container = styled.div`
	padding: 40px 16px;
	display: flex;
	justify-content: space-between;
`;

export const TextArea = styled.h5`
	font-size: 28px;
	line-height: 38px;
	font-weight: 600;
	max-width: 420px;
	color: #031e3f;
`;
