import React from 'react';

import { Container, Paragraph, Name } from './StepItem.styles';

// Interface
interface StepItemProps {
	status: 'base' | 'active';
	number: number;
	name: string;
}

const StepItem: React.FC<StepItemProps> = ({ status, number, name }) => {
	return (
		<Container status={status}>
			<Paragraph>{number}<Name>. {name}</Name></Paragraph>
		</Container>
	);
}

export default StepItem;