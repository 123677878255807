import { Listbox, Transition } from "@headlessui/react";
import { ChevronUpDownIcon } from "@heroicons/react/24/solid";
import StP3InformationLink from "components/atoms/InformationLink/InformationLink";
// Components
import StP3BoxContentInformation from "components/molecules/BoxContentInformation/BoxContentInformation";
import StP3BoxTextContent from "components/molecules/BoxTextContent/BoxTextContent";
import ModalParticipantes from "components/molecules/ModalParticipantes/ModalParticipantes";
import StP3InformationBox from "components/organisms/InformationBox/InformationBox";
import Button from "components/atoms/Button/Button";
import React, { Fragment, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { apiService, Consentimento, genericService, Organizacao, Seguradora } from "shared/services/generic/GenericService";
import { AccordionBox, BoxInfo, Container, LabelInfo } from './Step3.styles';
import { FINALIDADE, SHARE_FOR_MONTHS } from "shared/functions/getInstutionConsent.function";
import { useNavigate } from "react-router-dom";
import BoxPermissions from "components/molecules/BoxPermissions/BoxPermissions";
import Modal from "components/organisms/Modal/Modal";
import AlertMessage from "components/molecules/AlertMessage/AlertMessage";
import BoxGroupContent from "components/molecules/BoxGroupContent/BoxGroupContent";
import Text from "components/atoms/Text/Text";
import FlowInstitutions from "components/molecules/FlowInstitutions/FlowInstitutions";
import Accordion from "components/molecules/Accordion/Accordion";
import { ifTrueCall } from "utils/comparative.functions";
import { getDocumentType } from "utils/document.functions";

interface StepForm {
  nextStep: (step: number) => void;
  setDados: (dados: any) => void;
  setMeses: (meses: any) => void;
  setTitle: (title: React.ReactNode) => void;
  setAcceptedTerm: (accept: boolean) => void;
  renewConsent?: string | null;
  dados: any;
}

interface ConsentReturn {
  consentId: string;
  authorizationUrl: string;
}


const divLoad = (message: string = '', size: string = '2.5rem', height: string = 'auto') => {
  return (<>
    <div className="text-left" style={{ margin: "2px 8px", "height": height }}>
      <div role="status">
        <svg style={{ "width": size }} className="inline mr-2 w-10 h-10 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
          <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
        </svg>
        {message !== '' && <span className="sr-only" style={{ "overflow": "auto", "position": "inherit" }}>{message}</span>}
      </div>
    </div>
  </>)
}


export const getConsentiIdForChange = (cookies: any): string | undefined => {
  if(!cookies.consentIdForChange) return '';
  return atob(cookies.consentIdForChange);
}

export function parseData(consentimento: Consentimento, seguradora: Seguradora) {
  const institution = seguradora.institutions.find(obj => obj.organizationId === consentimento.institution)
  if(!institution) return { meses: null, parsedData: null}

  const document = consentimento.document.replaceAll(/[^0-9]/g, "");
  const meses = SHARE_FOR_MONTHS.find((p: any) => p.meses === parseInt(consentimento.deadLine.substring(0, 1)));
  const parsedData = {
    name: consentimento.name,
    cpf: document,
    finalidade: FINALIDADE,
    seguro: {
      name: institution.name,
      type: institution.type,
      logoUri: institution.logoUri,
      organizationId: institution.organizationId,
      authorizationUrl: institution.authorizationUrl,
      authorisationServerId: institution.authorisationServerId
    },
    permissoes: consentimento.permissions,
    meses: meses,
    acceptedTerm: false,
    documentType: getDocumentType(document)
  };
  return { meses, parsedData }
}

export const showDocumentFormated = (type: any = '', customerDocument: string = '') => {
  const document = customerDocument + "";
  let content = document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  if (type === 'CNPJ') {
    content = document.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
  }
  return <StP3BoxContentInformation title={type} content={content} />;
}

const isActive = (active: boolean) => {
  return active ? 'text-white' : 'text-black';
}

const classNames = (...classes: any) => {
  return classes.filter(Boolean).join(' ')
}

const getItemOfList = (open: boolean, currentSelected: any) => {
  return <>
    <Listbox.Label className="block text-sm font-medium text-gray-700 listboxTitle">Período</Listbox.Label>
    <div className="relative mt-1">
      <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm text-base font-normal focus:outline-none focus:ring-1 sm:text-sm">
        <span className="block truncate listboxTitle">{currentSelected ? currentSelected?.name : 'Selecione'}</span>
        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
          <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </span>
      </Listbox.Button>

      <Transition
        show={open}
        as={Fragment}
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          {SHARE_FOR_MONTHS.map((person, key) => (
            <Listbox.Option
              key={key}
              className={(active) => classNames(`relative cursor-default select-none py-2 pl-8 pr-4 text-black item-list`)}
              value={person}
            >
              {({ selected, active }) => (
                <>
                  <span className={classNames(`font-normal text-base listboxTitle ${!selected && 'block truncate'}`)}>
                    {person.name}
                  </span>

                  {selected && <span className={classNames(isActive(active), 'absolute inset-y-0 left-0 flex items-center pl-1.5')}></span>}
                </>
              )}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </Transition>
    </div>
  </>
}


const Step3: React.FC<StepForm> = ({ nextStep, dados, setDados, setMeses, setTitle, setAcceptedTerm, renewConsent }) => {
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(["customerDocument", "consentIdForChange", "consentRenew"]);
  const [currentSelected, setCurrentSelected] = useState<any>(SHARE_FOR_MONTHS[SHARE_FOR_MONTHS.length - 1]);
  const [term, setTerm] = useState(false);
  const [authorize, setAuthorize] = useState(false);
  const [messageException, setMessageException] = useState();
  const [shareModal, setShareModal] = useState<boolean>(false);
  const [organization, setOrganization] = useState<Organizacao>();
  const [organizacaoLoad, setOrganizacaoLoad] = useState<boolean>(true);
  const [messageReturn, setMessageReturn] = useState<string>();
  const [salvando, setSalvando] = useState(false);
  const [quitModal, setQuitModal] = useState<boolean>(false);
  const [redirectModal, setRedirectModal] = useState<boolean>(false);
  const [urlRedirect, setUrlRedirect] = useState<ConsentReturn>();

  const onSelectTerm = (checked: boolean) => ifTrueCall(!salvando, () => setTerm(checked));
  const onSelectAuthorize = (checked: boolean) => ifTrueCall(!salvando, () => setAuthorize(checked));

  useEffect(() => setTitle(<StP3BoxTextContent typeContent="title" textContent="Escolha o período de consentimento" />), [])
  useEffect(() => {
    window.scrollTo(0,0)
  },[])
  useEffect(() => {
    ifTrueCall(dados.seguro, () => {
      apiService.getOrganizationBy(dados.seguro.organizationId,dados.seguro.authorisationServerId)
        .then((org: Organizacao) => {
          setOrganization(org);
          setOrganizacaoLoad(false);
        }).catch((error: any) => {
          setMessageReturn(error.response.data?.errorDetail?.errorContent?.errors[0]);
        }).finally(() => {
          setOrganizacaoLoad(false);
        });
    });
  }, [dados]);

  useEffect(()=> setMeses(currentSelected), [currentSelected]);

  useEffect(() => {
    ifTrueCall(cookies.consentIdForChange, () => setCurrentSelected(dados.meses));

    ifTrueCall(cookies.consentRenew, () => {
      const consentId = atob(cookies.consentRenew as string);
      apiService.getConsentIdByConsentId(consentId)
        .then(respConsent => {
          apiService.getSeguradoras(respConsent.institutionName,cookies.customerDocument)
            .then(respOrg => {
                const { meses, parsedData } = parseData(respConsent, respOrg)
                setDados(parsedData);
                setCurrentSelected(meses);
            });
        });
    });
  }, [cookies]);


  const confirmarTerm = () => {
    setAcceptedTerm(true);
    setSalvando(true);
    let data: any = {
      logged_user_document: dados.cpf,
      logged_user_document_type: dados.documentType,
      nm_logged_user: dados.name,
      institution: dados.seguro.organizationId,
      permissions: [
        ...dados.permissoes.map((p: any) => p.key_name),
        "RESOURCES_READ",
      ],
      expiration_date_in_months: dados.meses.meses,
      authorisation_server_id: dados.seguro.authorisationServerId,
      old_consent_id: getConsentiIdForChange(cookies),
    };

    ifTrueCall(dados.documentType === "CNPJ", () =>  {
      data = {
        ...data,
        business_user_document: dados.cpf,
        business_user_document_type: dados.documentType,
      };
    });

    setMessageException(undefined);

    ifTrueCall(renewConsent === "true", () =>  {
      genericService
        .patch(
          `/consent/renew/${cookies.consentRenew}/document/${window.btoa(
            cookies.customerDocument)}`,
          null,
        )
        .then(async (response: any) => {
          setCookie("consentIdForChange", null, {
            path: "/",
            expires: new Date(),
          });
          setCookie("consentRenew", null, {
            path: "/",
            expires: new Date(),
          });

          setUrlRedirect({
            authorizationUrl: response.data.authorizationUrl,
            consentId: response.data.consentId,
          });
          setSalvando(false);
          setRedirectModal(true);
        })
        .catch((error) => {
          setSalvando(false);
          setMessageException(error.response?.data?.errors[0]);
        });
    }, () => {
      apiService
        .salvarCompartilhamento(data)
        .then(async (resp: any) => {
          setCookie("consentIdForChange", null, {
            path: "/",
            expires: new Date(),
          });
          setCookie("consentRenew", null, {
            path: "/",
            expires: new Date(),
          });

          setUrlRedirect({
            authorizationUrl: resp.authorizationUrl,
            consentId: resp.consentId,
          });
          setSalvando(false);
          setRedirectModal(true);
        })
        .catch((error: any) => {
          setSalvando(false);
          setMessageException(error.response?.data?.errors[0]);
        })
    });
  };

  const desistirCompartilhamento = () => {
    window.location.href = `/`;
  };


  const handleMySharedSumit = () => {
    navigate(`/meus-compartilhamentos`);
  }

  return (
    <Container className="container mx-auto">
      <StP3BoxTextContent
        typeContent="text"
        textContent="Defina o tempo que você deseja compartilhar esses dados com a gente: "
      />
      <div className="mx-auto sm:mx-4 sm:w-full sm:w-11/12 sm:max-w-md mt-6 ml-5 mr-5 justify-center item-center text-2xl font-bold font-sans">
        <Listbox data-testid="listboxComponent" value={currentSelected} onChange={setCurrentSelected} disabled={(salvando || cookies.consentRenew)}>
          {({ open }) => getItemOfList(open, currentSelected)}
        </Listbox>
        <h1 className="block mt-2 ml-1 listboxTitle">
          No encerramento do período, o compartilhamento será finalizado.
        </h1>
      </div>
      <BoxInfo className="mx-auto mt-5 justify-center item-center font-sans">
        <p>
          Mesmo escolhendo um prazo, você poderá revogar em qualquer momento na página <a onClick={handleMySharedSumit}>Meus compartilhamentos</a>
        </p>
      </BoxInfo>
      <StP3BoxTextContent typeContent="subtitle" textContent="Você está prestes a trazer os seguintes dados:" />
      <StP3InformationBox direction="column">
        <StP3BoxContentInformation title='Segurado' content={dados.name} />
        {showDocumentFormated(dados.documentType, dados.cpf)}
        <StP3BoxContentInformation title='Instituição selecionada' content={dados.seguro?.name} />

        {messageReturn && <div style={{ margin: "20px" }} className="flex p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800" role="alert">
          <svg aria-hidden="true" className="flex-shrink-0 inline w-5 h-5 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
          <span className="sr-only">Info</span>
          <div>
            <span className="font-medium">Erro!</span> {messageReturn}
          </div>
        </div>}

        {organizacaoLoad && divLoad('Carregando instituição...', '20px')}

        <StP3InformationLink style={{ "display": (!organizacaoLoad ? "initial" : "none") }} data-testid="openModalBTN" onClick={() => (setShareModal(true))}>Ver detalhes do participante</StP3InformationLink>

        <div className="border border-2 border-gray-200 mt-4 mb-4"></div>
        <StP3BoxContentInformation title='Dados Selecionados' content='' />
        <BoxPermissions permissoes={dados.permissoes} />
        <div className="w-full flex justify-end">
          <button disabled={cookies.consentRenew} className="py-2 px-2 text-sm mt-6 bg-gray-100 text-black rounded-md border-2 border-gray-300 border-rounded-md" data-testid="shareEditBTN" onClick={() => nextStep(2)}> Editar dados compartilhados</button>
        </div>
      </StP3InformationBox>


      <AccordionBox>
        <Accordion
          isOpen={false}
          title="TERMOS E CONDIÇÕES DE USO DO SITE OPEN INSURANCE PRUDENTIAL DO BRASIL"
          content={
            <Fragment>
              <p>
                Estes Termos e Condições de Uso (“Termos”) tem por objetivo, 
                juntamente com a Política de Privacidade (tratada ao longo deste documento), 
                descrever os termos e as condições aplicáveis ao acesso e uso das funcionalidades 
                do Open Insurance, por você, na qualidade de usuário deste benefício, 
                em conformidade com as leis e regulamentações que se aplicam a nós e nossas atividades.
              </p>
              <p>
                AO ACESSAR O SITE DO OPEN INSURANCE, VOCÊ ESTARÁ AUTOMATICAMENTE SUJEITO ÀS DISPOSIÇÕES DESTES TERMOS E CONDIÇÕES DE USO.
              </p>
              <p>
              Você manifesta sua vontade de forma livre, informada e inequívoca de AUTORIZAR a 
              instituição selecionada a compartilhar com a Prudential do Brasil, 
              no âmbito do Open Insurance, os seus dados cadastrais e/ou transacionais 
              selecionados para as finalidades informadas, conforme estes Termos e Condições, 
              e ratifica sua CIÊNCIA da Política de Privacidade (disponível em{" "}
                <a
                  href="https://www.prudential.com.br/politica-de-privacidade"
                  target="_blank"
                  rel="noreferrer"
                >
                  www.prudential.com.br/politica-de-privacidade
                </a>),que explica como a Prudential do Brasil, na qualidade de controladora de dados pessoais, 
                trata os seus dados pessoais, de acordo com a LGPD – Lei Geral de Proteção de 
                Dados Pessoais (Lei nº 13.709/18) e demais legislações aplicáveis.
              </p>
              <p>
                <strong>1. O que é o Open Insurance</strong>
              </p>
              <p>
              O Open Insurance, ou Sistema de Seguros Aberto, é a possibilidade de consumidores de produtos e 
              serviços de seguros, previdência complementar aberta e capitalização 
              permitirem o compartilhamento de suas informações entre diferentes 
              sociedades autorizadas/credenciadas pela SUSEP, de forma segura, ágil, 
              precisa e conveniente. Para entregar esses benefícios ao consumidor, 
              o Open Insurance operacionaliza e padroniza o compartilhamento de dados e 
              serviços por meio de abertura e integração de sistemas, com privacidade e segurança.
              </p>
              <p>
                <strong>2. Benefícios do Open Insurance</strong>
              </p>
              <p>
              Com o Open Insurance você pode autorizar, de forma segura, ágil e digital, o compartilhamento 
              padronizado de seus dados cadastrais e transacionais, entre diferentes instituições 
              autorizadas e supervisionadas pela SUSEP. Você decide quais dados, quando e com quem 
              deseja compartilhá-los, desde que seja com finalidades específicas e prazos determinados. 
              O Open Insurance visa  dinamizar o mercado de seguros nacional, ampliar a competitividade do 
              mercado e, como consequência, fornecer melhores produtos e serviços aos clientes, em termos de 
              preços e condições, conforme seu perfil e necessidades.
              </p>
              <p>
                <strong>3. Segurança</strong>
              </p>
              <p>
              Todo o processo de compartilhamento dos dados no Open Insurance ocorre em ambiente com diversas 
              camadas de segurança, com autenticação do usuário e das instituições participantes. 
              As instituições são responsáveis pela qualidade dos dados (informação mais recente possível, adequada e compatível) 
              e pela segurança do seu compartilhamento, devendo cumprir rigorosas regras de segurança cibernética.
              </p>
              <p>
                <strong>4. Supervisão e Regras</strong>
              </p>
              <p>
              A SUSEP supervisiona todo o processo e apenas as instituições autorizadas participam, 
              devendo obedecer às regras estabelecidas  nos atos normativos publicados pelo 
              CONSELHO NACIONAL DE SEGUROS PRIVADOS - CNSP e pela SUPERINTENDÊNCIA DE SEGUROS PRIVADOS - SUSEP. 
              Essas regras abrangem, dentre outros assuntos, as responsabilidades pelo compartilhamento e as 
              características obrigatórias desse processo, que inclui as etapas de consentimento (autorização de compartilhamento), 
              autenticação (verificação de identidade) e confirmação pelo usuário.
              </p>
              <p>
                <strong>5. Etapas</strong>
              </p>
              <p>
              Você deve iniciar a solicitação do compartilhamento de dados na instituição participante receptora, 
              ou seja, na instituição que receberá os seus dados. Para tal, deverá realizar cadastro junto à Prudential do Brasil, 
              informando seus dados de identificação necessários para continuar com o processo de compartilhamento. 
              <p>O usuário é responsável pela veracidade dos dados informados, sendo o envio de informações incorretas capaz de impactar 
              ou interromper o seu acesso ao Open Insurance. A instituição receptora dos dados é responsável por fazer a sua correta 
              identificação e assegurar a prestação de informações de forma clara, objetiva e adequada, bem como por obter o seu 
              consentimento para finalidades determinadas. </p>
              Após essa etapa, você será redirecionado para o ambiente eletrônico da instituição participante transmissora, 
              ou seja, para a instituição que enviará os seus dados para a instituição receptora. A instituição transmissora 
              dos dados é responsável por fazer a sua autenticação e confirmar o seu consentimento. Caso venham a ser alteradas 
              as finalidades do compartilhamento dos dados, será solicitado a você novo consentimento.
              </p>
              <p>
                <strong>6. Prazo</strong>
              </p>
              <p>
              Para compartilhar os dados no Open Insurance, você deverá fornecer o seu consentimento, que deverá ser obtido para 
              finalidade(s) determinada(s) e com prazo de validade definido por você. A participação no Open Insurance é 
              completamente facultativo, constituindo opção livre por parte do usuário.
              </p>
              <p>
                <strong>6.1. Prazo do consentimento</strong>
              </p>
              <p>
              O consentimento  será sempre fornecido com base em prazo previamente definido por você, com um período máximo de 12 meses. 
              Após o decurso do prazo selecionado e, caso não haja renovação, tal consentimento fornecido no âmbito do Open Insurance 
              será inativado e o compartilhamento será interrompido.
              </p>
              <p>
                <strong>6.2 Gestão de consentimentos</strong>
              </p>
              <p>
              Sempre que desejar, você pode consultar informações sobre os seus consentimentos vigentes nas instituições participantes 
              envolvidas no compartilhamento dos dados. Para consultar seus consentimentos concedidos à Prudential, basta acessar nossas 
              aplicações e selecionar ‘Gerenciar meus compartilhamentos’.
              </p>
              <p>
                <strong>6.3. Revogação do consentimento</strong>
              </p>
              <p>
              As instituições participantes envolvidas no compartilhamento dos dados devem assegurar  a você a possibilidade de, 
              a qualquer tempo e por meio de procedimento rápido e seguro, revogar o consentimento para que o compartilhamento de 
              dados seja encerrado.
              <p>
              Caso você revogue seu consentimento no âmbito do Open Insurance, será interrompido o fluxo de dados entre as partes 
              envolvidas no compartilhamento. Tal ação não trará nenhum prejuízo à sua relação com a Prudential, não gerando ônus 
              para você ou para a empresa. A revogação do consentimento afetará a possibilidade de armazenamento de dados oriundos 
              diretamente do Open Insurance por parte da Prudential, não sendo afetadas as informações derivadas do seu relacionamento 
              com a Prudential enquanto cliente ou potencial cliente, que serão tratadas de acordo com a <a href="https://www.prudential.com.br/politica-de-privacidade.html" target="_blank">Política de Privacidade</a>
              </p>
              </p>
              <p>
                <strong>7. Tratamento de dados</strong>
              </p>
              <p>
                <strong>7.1. Dados coletados através do Open Insurance</strong>
              </p>
              <p>
              Através do Open Insurance, a Prudential poderá acessar as seguintes categorias de dados:
              <p> •	Dados de identificação (exemplo: informações pessoais, informações relativas à seguradora contratada, documentos, dados de contato);</p>
              <p> •	Dados de qualificação (exemplo: profissão, renda, dados sobre pessoas politicamente expostas);</p>
              <p>•	Dados de relacionamento (exemplo: informações sobre seguros e relacionamento com seguradoras);</p>
              <p>•	Dados transacionais (exemplo: identificação de seguros contratados, informações sobre os contratos e informações sobre sinistros).</p>
              Os dados a serem compartilhados serão selecionados por você, de forma facultativa e expressa, durante a sua jornada no Open Insurance.
              </p>
              <p>
                <strong>7.2. Finalidade do tratamento</strong>
              </p>
              <p>
              O tratamento dos dados compartilhados sempre ocorrerá de acordo com os limites do seu consentimento, 
              que deverá indicar a(s) finalidade(s) informada(s) e relacionada(s) ao exercício de nossas atividades, 
              tipo de dados, prazo e instituição a ser compartilhados. Dados de terceiros que eventualmente constem 
              de seus dados cadastrais e/ou transacionais também poderão ser tratados de acordo com essa(s) finalidade(s), 
              sempre de acordo com a legislação aplicável.
              Os seus dados pessoais poderão ser utilizados para conhecer as suas preferências e 
              estruturar melhores condições de prestação de serviço e de contratação de produtos. 
              Seus dados poderão ser utilizados para operações como atualização de cadastro, apresentação 
              de propostas relacionadas ao seu perfil e oferta de novos produtos e serviços da Prudential 
              ou de seus parceiros comerciais.
              <p>
              Toda operação de tratamento de dados realizada pela Prudential será conduzida com base na 
              <a href="https://www.prudential.com.br/politica-de-privacidade.html" target="_blank">Política de Privacidade</a> 
              e terá como fundamento as exigências legais estabelecidas 
              no programa do Open Insurance, bem como aquelas estabelecidas na legislação, a exemplo da 
              Lei nº 13.709 de 2018 (‘Lei Geral de Proteção de Dados’ ou ‘LGPD’) e da Lei nº 12.965 de 2014 
              (‘Marco Civil da Internet’).
              </p>

              </p>
              <p>
                <strong>8. Disposições finais</strong>
              </p>
              <p>
              O teor destes Termos e Condições poderá ser atualizado ou modificado a qualquer momento tempo, 
              para garantir o nosso compromisso de transparência com o usuário e adequação a legislação aplicável.
              Caso o usuário não aceite estes Termos e Condições, a sua recusa implicará na impossibilidade de 
              compartilhamento de informações entre as instituições, inviabilizando a sua participação no 
              Open Insurance. Ademais, caso venha a revogar o seu aceite a estes Termos e Condições, 
              o compartilhamento será imediatamente interrompido, sem prejuízo a sua relação com a Prudential.

              </p>
            </Fragment>
          }
        />
      </AccordionBox>

      {salvando && (
        <div className="text-center" style={{ margin: "30px" }}>
          <div role="status">
            <svg
              className="inline mr-2 w-10 h-10 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
            <span className="sr-only">Salvando...</span>
          </div>
        </div>
      )}

      {messageException && (
        <div
          style={{ margin: "20px" }}
          className="flex p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800"
          role="alert"
        >
          <svg
            aria-hidden="true"
            className="flex-shrink-0 inline w-5 h-5 mr-3"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
              clip-rule="evenodd"
            ></path>
          </svg>
          <span className="sr-only">Info</span>
          <div>
            <span className="font-medium">Erro!</span> {messageException}
          </div>
        </div>
      )}

      <div className="flex items-center mt-6 mb-6 ml-5 mr-5">
        <input
          data-testid="checkGiveUpAuthorization"
          type="checkbox"
          disabled={salvando}
          checked={authorize}
          className="w-4 h-4 text-blue-600 bg-gray-50 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          onChange={(e) => onSelectAuthorize(e.target.checked)}
        />
        <LabelInfo className="ml-2 text-base font-normal text-gray-700 terms select-none" onClick={() => onSelectAuthorize(!authorize)}>
          Estou de acordo e dou consentimento para a Prudential do Brasil receber meus dados pessoais e/ou de meus seguros selecionados para as finalidades informadas no âmbito do Open Insurance.
          Para mais informações sobre o tratamento de dados pessoais, consultar a <a href="https://www.prudential.com.br/politica-de-privacidade.html" target="_blank">Política de Privacidade</a>e os Termos e Condições
          .
        </LabelInfo>
      </div>

      <div className="flex items-center mt-6 mb-6 ml-5 mr-5">
        <input
          data-testid="checkGiveUpSharing"
          type="checkbox"
          disabled={salvando}
          checked={term}
          className="w-4 h-4 text-blue-600 bg-gray-50 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          onChange={(e) => onSelectTerm(e.target.checked)}
        />
        <label className="ml-2 text-base font-normal text-gray-700 terms select-none" onClick={() => onSelectTerm(!term)}>
          Estou ciente dos termos e condições.
        </label>
      </div>

      <div className="sm:mx-auto sm:w-full sm:w-11/12 sm:max-w-md mt-6 mb-6 ml-5 mr-5 justify-center item-center text-2xl font-bold font-sans">
        <Button
          data-testid="confirmBTN"
          layout="yellow"
          onClick={confirmarTerm}
          disabled={!term || !authorize || salvando}
        >
          Confirmar
        </Button>
        <Button
          data-testid="giveUpSharing"
          layout="text"
          onClick={() => setQuitModal(true)}
        >
          Desistir do compartilhamento
        </Button>
      </div>

      <ModalParticipantes organization={organization} setShareModal={setShareModal} shareModal={shareModal} />

      <Modal
        title="Redirecionamento"
        type="alternative"
        noClose
        showModal={redirectModal}
        setShowModal={setRedirectModal}
      >
        <AlertMessage
          type="warning"
          icon={
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224c0-17.7-14.3-32-32-32s-32 14.3-32 32s14.3 32 32 32s32-14.3 32-32z" />
            </svg>
          }
          content="Não feche a aba deste site até que o consentimento de dados seja concluído."
        />

        <BoxGroupContent>
          <Text>
            Para concluir o compartilhamento você será redirecionado para a
            instituição escolhida, através do Open Insurance.
          </Text>
        </BoxGroupContent>

        <BoxGroupContent align="center">
          <FlowInstitutions
            urlRedirect={urlRedirect}
            customerDocument={dados.cpf}
            receiverLogo="https://www.prudentialdobrasil.com.br/PublishingImages/Logotipo-OPIN/logotipo-prudential.svg"
            transmitterLogo={dados.seguro?.logoUri}
          />
        </BoxGroupContent>
      </Modal>

      <Modal
        title="Tem certeza que deseja desistir do compartilhamento?"
        showModal={quitModal}
        setShowModal={setQuitModal}
      >
        <BoxGroupContent>
          <Button
            data-testid="modalGiveUpSharing"
            onClick={() => desistirCompartilhamento()}
            layout="base"
            spacing="small"
          >
            Desistir do compartilhamento
          </Button>
        </BoxGroupContent>
      </Modal>
    </Container>
  )
}

export default Step3