import styled from "styled-components";

export const Container = styled.div`
  background-color: #e5e7eb;
`;

export const Box = styled.div`
  background-color: #fff;
  border: 1px solid #9ca3af;
  margin: 0 auto;
  max-width: 448px;
  width: 100%;

  @media (min-width: 600px) {
    max-width: none;
  }
`;
