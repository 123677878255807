import styled from 'styled-components';

export const Container = styled.div`
	background-color: #222222;
	padding: 16px 0;
`;

export const Box = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: start;
	align-items: flext-start;
	padding: 0 16px;
	
	@media(min-width: 1280px){
		flex-direction: row;
		align-items: center;
	}
`;

export const Separator = styled.hr`
	color: #ccc;
	margin: 20px auto;
`;
