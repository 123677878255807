import styled from 'styled-components';

export const Container = styled.div`
	
`;

export const Button = styled.button `
  background-color: #07639d;
  border-radius: 3px;
  border: solid 1px #07639d;
  color: #fff;
  display: block;
  font-size: 15px;
  height: 45px;
  margin: 0 auto;
  text-align: center;
  width: 232px;
  
  &:disabled {
    background-color: #afafaf;
    color: #999999;
    border: 1px solid #afafaf
  }
;
`;