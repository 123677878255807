import styled from "styled-components";

export const Container = styled.div`
  border: 1px solid #cccccc;
  padding: 16px 16px;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }

  @media (min-width: 600px) {
    flex-basis: 31%;
    margin-bottom: 0;
  }
`;
