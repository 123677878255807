import React from 'react';

// Components
import Logo from "components/atoms/Logo/Logo";

// Assets
import PrudentialWhiteLogo from "assets/img/Logo-Horizontal-Prudential-Branca-CURVAS.svg"

// Styles
import { Container, Box } from './Header.styles';


const Header: React.FC = () => {
  return (
    <Container>
      <Box>
        <Logo src={PrudentialWhiteLogo} alt="Prudential do Brasil"/>
      </Box>
    </Container>
  );
}

export default Header;