import styled from "styled-components";

export const Container = styled.div`
  margin: 24px 16px 40px;

  @media (min-width: 600px) {
    margin: 24px auto 40px;
    width: 80%;
  }
`;

export const BoxButtons = styled.div`
  display: flex;
  margin-top: 16px;

  @media (min-width: 600px) {
    width: 60%;
  }
`;
