import styled, { css } from "styled-components";

interface Props {
  type: "warning" | "error" | "success" | "redirect";
  noMargin?: boolean;
}

const warningColor = "#ee0006";
const errorColor = "#ee0006";
const successColor = "#297c00";
const redirectColor = "#c15505";

export const Container = styled.div<Props>`
  border: 1px solid ${warningColor};
  border-top: 10px solid ${warningColor};
  display: flex;
  margin-bottom: 24px;
  padding: 16px;
  margin: 16px ${({ noMargin }) => noMargin && "0px"};

  ${({ type }) =>
    type === "success" &&
    css`
      fill: ${successColor};
    `}

  ${({ type }) =>
    type === "success" &&
    css`
      border: 1px solid ${successColor};
      border-top: 10px solid ${successColor};
    `}

	${({ type }) =>
    type === "error" &&
    css`
      border: 1px solid ${errorColor};
      border-top: 10px solid ${errorColor};
    `}

	${({ type }) =>
    type === "redirect" &&
    css`
      border: 1px solid ${redirectColor};
      border-top: 10px solid ${redirectColor};
    `}
`;

export const BoxIcon = styled.div<Props>`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding-right: 10px;

  svg {
    fill: ${warningColor};
    height: auto;
    width: 24px;

    ${({ type }) =>
      type === "success" &&
      css`
        fill: ${successColor};
      `}

    ${({ type }) =>
      type === "error" &&
      css`
        fill: ${errorColor};
      `}

		${({ type }) =>
      type === "redirect" &&
      css`
        fill: ${redirectColor};
      `}
  }
`;

export const BoxContent = styled.div`
  color: #1a1d1e;
  font-size: 15px;
  font-weight: normal;

  p {
    color: #1a1d1e;
    font-size: 15px;
    font-weight: normal;
  }
`;

export const Title = styled.h3<Props>`
  color: ${warningColor};
  display: block;
  font-size: 20px;
  font-weight: bold;
  line-height: 1;
  margin-bottom: 6px;

  ${({ type }) =>
    type === "success" &&
    css`
      color: ${successColor};
    `}

  ${({ type }) =>
    type === "error" &&
    css`
      color: ${errorColor};
    `}

	${({ type }) =>
    type === "redirect" &&
    css`
      color: ${redirectColor};
    `}
`;
