import React, { useEffect, useState } from "react";

// Atoms
import BackButton from "components/atoms/BackButton/BackButton";

// Organisms
import Footer from "components/organisms/Footer/Footer";
import Header from "components/organisms/Header/Header";
import StepBar from "components/organisms/StepBar/StepBar";

// Templates
import FirstHead from "components/molecules/FirstHead/FirstHead";
import Step1 from "components/templates/step-1/Step1";
import Step2 from "components/templates/step-2/Step2";
import Step3 from "components/templates/step-3/Step3";
import { useCookies } from "react-cookie";

const stepListStatus = [
  {
    status: "active",
    number: 1,
    name: "Instituições",
  },
  {
    status: "base",
    number: 2,
    name: "Dados",
  },
  {
    status: "base",
    number: 3,
    name: "Consentimentos",
  },
  {
    status: "base",
    number: 4,
    name: "Solicitação",
  },
];

const sharedData = {
  name: null,
  cpf: null,
  finalidade: null,
  seguro: null,
  permissoes: [],
  meses: null,
  acceptedTerm: false,
};

const Home: React.FC = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const renewConsent = urlParams.get("renovar");

  const [cookies] = useCookies(["consentRenew"]);

  const [formStep, setFormStep] = useState(cookies.consentRenew ? 3 : 1);
  const [pointStepShow, setPointStepShow] = useState<any[]>(stepListStatus);
  const [dataForShare, setDataForShare] = useState(sharedData);
  const [title, setTitle] = useState<React.ReactNode | undefined>(undefined);

  useEffect(() => {
    let list = pointStepShow.map((it: any) => {
      return {
        status: formStep >= it.number ? "active" : "base",
        number: it.number,
        name: it.name,
      };
    });
    setPointStepShow(list);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formStep]);

  const actions = {
    nextStep: (step: number) => {
      setFormStep((cur) => step);
      setTitle('');
    },
    setNomeCPFSegurado: (name: string, cpf: string, documentType: string) =>
      setDataForShare((st: any) => {
        return { ...st, name, cpf, documentType };
      }),
    setSeguro: (seguro: any) =>
      setDataForShare((st: any) => {
        return { ...st, seguro };
      }),
    setFinalidade: (finalidade: string) =>
      setDataForShare((st: any) => {
        return { ...st, finalidade };
      }),
    setPermissoes: (permissoes: any[]) =>
      setDataForShare((st: any) => {
        return { ...st, permissoes };
      }),
    setMeses: (meses: any) =>
      setDataForShare((st: any) => {
        return { ...st, meses };
      }),
    setAcceptedTerm: (acceptedTerm: boolean) =>
      setDataForShare((st: any) => {
        return { ...st, acceptedTerm };
      }),
    setDados: (dados: any) =>
      setDataForShare((st: any) => {
        return { ...st, ...dados };
      }),
  };

  return (
    <div>
      <Header />
      <FirstHead />
      <div className="min-h-full w-full sm:bg-gray-200 flex flex-col justify-center">
        <div className="sm:w-full sm:bg-white">
          {title}
          <div className="container mx-auto">
            <BackButton
              currentStep={formStep}
              nextStep={actions.nextStep}
              type="steps"
            >
              Voltar
            </BackButton>
            <StepBar list={pointStepShow} />
          </div>
          {formStep === 1 && (
            <Step1
              nextStep={actions.nextStep}
              setDados={actions.setDados}
              dados={dataForShare}
              setNomeCPFSegurado={actions.setNomeCPFSegurado}
              setFinalidade={actions.setFinalidade}
              setSeguro={actions.setSeguro}
              setTitle={setTitle}
            />
          )}
          {formStep === 2 && (
            <Step2
              nextStep={actions.nextStep}
              setPermissoes={actions.setPermissoes}
              dados={dataForShare}
              setTitle={setTitle}
            />
          )}
          {formStep === 3 && (
            <Step3
              nextStep={actions.nextStep}
              dados={dataForShare}
              setDados={actions.setDados}
              setMeses={actions.setMeses}
              setAcceptedTerm={actions.setAcceptedTerm}
              renewConsent={renewConsent}
              setTitle={setTitle}
            />
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default Home;
