/* eslint-disable react-hooks/exhaustive-deps */
// Assets
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import BoxContentInformation from "components/molecules/BoxContentInformation/BoxContentInformation";
// Components
import Loading from "components/atoms/Loading/Loading";
import AlertMessage from "components/molecules/AlertMessage/AlertMessage";
import BoxTextContent from "components/molecules/BoxTextContent/BoxTextContent";
import InformationBox from "components/organisms/InformationBox/InformationBox";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useForm } from "react-hook-form";
import InfiniteScroll from "react-infinite-scroll-component";
// Services
import { Seguradora, apiService } from "shared/services/generic/GenericService";
// Styles
import { AccordionBox, Button, Container, Input } from "./Step1.styles";
import { FINALIDADE, getInstitutionConsent } from "shared/functions/getInstutionConsent.function";
import Accordion from "components/molecules/Accordion/Accordion";
import { ifTrueCall } from "utils/comparative.functions";

const limitDefaultDesktop = 12;
const limitWidthDesktop = 900;
let findOrg: any = null;

interface StepForm {
  nextStep: (step: number) => void;
  setNomeCPFSegurado: (name: string, cpf: any, documentType: any) => void;
  setSeguro: (seguro: any) => void;
  setFinalidade: (finalidade: string) => void;
  dados: any;
  setDados: (dados: any) => void;
  setTitle: (title: React.ReactNode) => void;
}

const hashCode = (str: string) => {
  var hash = 0,
    i,
    chr;
  if (str.length === 0) return hash;
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

const defaultImage = 'https://opinbrasil.com.br/_nuxt/img/icon-open-insurance.e459120.svg';

const checkOrganizationName = (org: string, lastOrg: string) => {
  return org.length >= 3 || org === "" || (org.length <= 2 && org.length < lastOrg.length);
}

const getValueOrAnother = (value1: any, value2: any) => {
  return value1 || value2;
}

const ifTrueReturn = (condi: boolean, value1: any, value2: any) => {
  return condi ? value1 : value2;
}

const getInfinitScroll = (
  consultando: boolean,
  segurosFiltros: any[],
  hasSeguradoras: boolean,
  dimensions: any,
  seguroSelecionado: any,
  actions: {
    fetchMoreData: () => any,
    register: (v: any, v2: any) => any,
    selectSeguro: (v: any) => any,
    testLoadding: (v: any) => any
  }
) => {
  if (consultando || segurosFiltros.length === 0) {
    return null;
  }

  return <>
    <InfiniteScroll
      dataLength={segurosFiltros.length}
      next={() => actions.fetchMoreData()}
      hasMore={hasSeguradoras}
      height={dimensions?.width > limitWidthDesktop ? 150 : 260}
      scrollThreshold={0.9}
      loader={<Loading text="Carregando" />}
    >
      {segurosFiltros.map((seguro: any, id: any) => (
        <div key={id} className="relative items-center2">
          <div className="flex items-center h-5">
            <Input
              {...actions.register(seguro.name, { required: true })}
              name="seguros"
              onClick={() => actions.selectSeguro(seguro)}
              checked={seguroSelecionado != null && seguro.organizationId === seguroSelecionado.organizationId && seguro.authorisationServerId === seguroSelecionado.authorisationServerId}
              key={seguro.name}
              id={seguro.organizationId}
              value={seguro}
              data-testid="inputCheck"
              type="radio"
              className="text-gray-600 focus:ring-offset-2 focus:ring-white rounded-full input-list"
            />
          </div>
          <div className="ml-3 text-sm" onClick={() => actions.selectSeguro(seguro)}>
            <label
              htmlFor="comments"
              className="font-medium flex items-center text-gray-700"
            >
              {actions.testLoadding(seguro)}
              <h1 className="ml-2">{seguro.name}</h1>
            </label>
          </div>
        </div>
      ))}
    </InfiniteScroll>
  </>;
}

const Step1: React.FC<StepForm> = ({
  nextStep,
  setNomeCPFSegurado,
  setSeguro,
  setFinalidade,
  dados,
  setDados,
  setTitle
}) => {
  const { register } = useForm({
    mode: "all",
  });
  useEffect(() => {
    window.scrollTo(0,0)
  },[])
  const [cookies, setCookie] = useCookies(["customerDocument", "consentIdForChange", "consentRenew"]);
  const [hasSeguradoras, setHasSeguradoras] = useState(true);
  const [organizationName, setOrganizationName] = useState("");
  const [lastOrganizationName, setLastOrganizationName] = useState("");
  const [seguroSelecionado, setSeguroSelecionado] = useState<any>(null);
  const [consultando, setConsultando] = useState(false);
  const [receiverData, setReceiverData] = useState<Seguradora | null>(null);
  const [loadError, setLoadError] = useState<boolean>(false);
  const [customerDocumentEncrypted, setCustomerDocumentEncrypted] = useState<string | undefined>();
  const [customerDocument, setCustomerDocument] = useState<string | undefined>();
  const [dimensions, setDimensions]: any = useState(null);

  

  ifTrueCall(cookies.customerDocument,
    () => {
      const date = new Date();
      date.setDate(date.getDate() + 10);
      setCookie("customerDocument", cookies.customerDocument, {
        path: "/",
        expires: date,
      });
      if(!customerDocumentEncrypted)
        setCustomerDocumentEncrypted(cookies.customerDocument);
    }
  );

  const fetchMoreData = () => {
    const quant = segurosFiltros.length;
    apiService
      .getSeguradoras(organizationName, customerDocumentEncrypted, quant)
      .then((data: Seguradora) => {
        setHasSeguradoras(data.hasNext);
        setReceiverData((curr: any) => {
          const currentList = [...curr.institutions, ...data.institutions];
          return { ...curr, ...data, institutions: currentList };
        });
        setLoadError(false);
      })
      .catch(() => {
        setLoadError(true);
      });
  };

  const getData = (offset: number = 0) => {
    setLoadError(false);
    setConsultando(true);
    apiService
      .getSeguradoras(
        organizationName,
        customerDocumentEncrypted,
        offset,
        getValueOrAnother(dimensions?.amount, limitDefaultDesktop),
      )
      .then((data: Seguradora) => {
        setHasSeguradoras(data.hasNext);
        setReceiverData(data);
        setNomeCPFSegurado(
          data.customerName,
          data.customerDocument,
          data.typePerson,
        );
        setFinalidade(FINALIDADE);
        setCustomerDocument(data.customerDocument);
      })
      .catch(() => {
        setLoadError(true);
      })
      .finally(() => {
        setConsultando(false);
        setLastOrganizationName(organizationName);
      });
  };

  useEffect(() => {
    ifTrueCall(cookies.consentIdForChange, () => {
      const consentId = atob(cookies.consentIdForChange as string);
      getInstitutionConsent(consentId)
        .then(sharedData => {
          setDados(sharedData ?? []);
          setSeguroSelecionado(sharedData?.seguro ?? {});
          setOrganizationName(sharedData?.seguro.name ?? '');
        });
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies]);

  let segurosFiltros: any[] = [];
  if (receiverData) {
    segurosFiltros = receiverData?.institutions;
  }

  const selectSeguro = (seguro: any) => {
    setSeguroSelecionado(seguro);
  };

  const completeInformationAndNextStep = () => {
    ifTrueCall(!cookies.consentRenew, () => setSeguro(seguroSelecionado));
    nextStep(2);
  }

  const testLoadding = (seguro: any) => {
    const hash_id = hashCode(seguro.name);

    const loadImage = new Promise(() => {
      const img = new Image();
      img.onload = function () {
        const img_ = document.querySelector(`#_img_${hash_id}`);
        img_?.setAttribute("src", img.src);
        img_?.setAttribute("class", 'w-10 h-10 iconImageOrganization');
      };
      img.onerror = img.onabort = function () {
        const img_ = document.querySelector(`#_img_${hash_id}`);
        img_?.setAttribute("src", defaultImage);
        img_?.setAttribute("class", 'w-10 h-10 iconImageOrganization');
      };
      img.src = seguro.logoUri;
    });
    loadImage.finally(() => console.log('endLoad'));
    return <img
      id={`_img_${hash_id}`}
      alt={seguro.name}
      src={seguro.logoUri}
      className="w-10 h-10 iconImageOrganization iconImageOrganization_load" />;
  }

  const showDocumentFormated = (type: any = "") => {
    const document = customerDocument + "";
    let content = document.replace(
      /(\d{3})(\d{3})(\d{3})(\d{2})/,
      "$1.$2.$3-$4",
    );
    ifTrueCall(type === "CNPJ", () => {
      content = document.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")
    });
    return <BoxContentInformation title={type} content={content} contentStrong={true} />;
  };

  const setFind = (value: string) => {
    setOrganizationName(value);
    setSeguroSelecionado(null);
  };



  useEffect(() => {
    ifTrueCall(cookies.consentRenew, () => {
      setOrganizationName(dados.seguro.name);

      const seguradora = {
        name: dados.seguro.name,
        type: dados.seguro.type,
        logoUri: dados.seguro.logoUri,
        organizationId: dados.seguro.organizationId,
        authorizationUrl: dados.seguro.authorizationUrl,
        authorisationServerId: dados.seguro.authorisationServerId
      };
      setReceiverData((dt: any) => ({
        ...dt,
        customerName: dados.name,
        typePerson: dados.documentType,
        institutions: [seguradora]
      }));

      selectSeguro(seguradora);
    });
    ifTrueCall(!cookies.consentRenew && (dimensions || checkOrganizationName(organizationName, lastOrganizationName)), () => {
      if (!organizationName) {
        getData();
      } else {
        clearTimeout(findOrg);
        findOrg = setTimeout(() => {
          getData();
        }, 1000);
      }
    });

  }, [organizationName, cookies]);

  useEffect(() => {
    setTitle(<BoxTextContent
      typeContent="title"
      textContent="Comece seu pedido de compartilhamento de dados"
    />)
    
    function handleResize() {
      const divContatiner = document.querySelector("#defaultContatiner");
      setDimensions({
        width: divContatiner?.clientWidth,
        height: divContatiner?.clientHeight,
        amount: limitDefaultDesktop,
      });
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Container id="defaultContatiner">
      <Container className="container mx-auto">
        <BoxTextContent
          typeContent="text"
          textContent="Você vai trazer os dados de:"
        />
        <InformationBox>
          {receiverData ?
            <>
              <BoxContentInformation
                title="Segurado"
                content={receiverData?.customerName}
                contentStrong={true}
              />
              {showDocumentFormated(receiverData?.typePerson)}
            </>
            : <Loading text="Carregando" />
          }
        </InformationBox>
        <AccordionBox>
          <Accordion isOpen={false}
            title="Finalidade do uso de dados"
            content={FINALIDADE} />
        </AccordionBox>
        

        <BoxTextContent typeContent="subtitle" textContent="Origem dos dados" />
        <BoxTextContent
          typeContent="textStrong"
          textContent="Busque por uma instituição"
        />

        <form action="#">
          <div className="mt-6 ml-5 mr-5 justify-center items-centerpy-2">
            <div>
              <div className="relative sm:w-full sm:w-11/12 sm:max-w-md">
                <input
                  disabled={(cookies.consentRenew)}
                  type="text"
                  data-testid="inputCategoria"
                  onChange={(e) => setFind(e.target.value)}
                  value={organizationName}
                  placeholder="Nome da instituição"
                  className="inputInstitution block w-full py-3 pl-4 pr-12 text-base text-slate-900 rounded-md placeholder:text-slate-600 focus:outline-none sm:text-sm sm:leading-6 border-2 px-2 border-gray-300"
                />
                <MagnifyingGlassIcon className="absolute top-4 right-4 h-6 w-6 fill-slate-400" />
              </div>

              {consultando ? <Loading text="Carregando" /> : null}

              {getInfinitScroll(
                consultando,
                segurosFiltros,
                hasSeguradoras,
                dimensions,
                seguroSelecionado,
                {
                  fetchMoreData,
                  register,
                  selectSeguro,
                  testLoadding
                })}

              {ifTrueReturn(loadError, (
                <div className="mx-auto w-full max-w-md rounded-2xl bg-white p-2">
                  <div className="mss-load" onClick={() => getData()}>
                    Erro ao carregar, tentar novamente.
                  </div>
                </div>
              ), null)}

              {ifTrueReturn(!consultando && segurosFiltros.length === 0, (
                <AlertMessage
                  type="warning"
                  icon={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224c0-17.7-14.3-32-32-32s-32 14.3-32 32s14.3 32 32 32s32-14.3 32-32z" />
                    </svg>
                  }
                  title="Dados não encontrados"
                  content="Desculpe, nenhuma seguradora participante do Open Insurance corresponde a sua busca. Verifique a digitação ou busque outra instituição."
                  noMargin
                />
              ), null)}
            </div>
          </div>
          <div className="w-full flex justify-center mb-4 w-full">
            <Button
              data-testid="handleSubmitBTN"
              type="submit"
              onClick={completeInformationAndNextStep}
              className="bg-gray-200 py-2 w-1/2 flex justify-center items-center "
              disabled={!seguroSelecionado}
            >
              Continuar
            </Button>
          </div>
        </form>
      </Container>
    </Container>
  );
};

export default Step1;
