import React, { AnchorHTMLAttributes } from 'react';

import { Container } from './Anchor.styles';

// Interface
interface AnchorProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
	children: React.ReactNode;
	type?: 'base' | 'blue' | 'icon'
}

const Anchor: React.FC<AnchorProps> = ({ children, type, ...rest }) => {
	return <Container data-testid="AnchorBTN" type={type} {...rest}>{children}</Container>;
}

export default Anchor;