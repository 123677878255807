import styled from "styled-components";

export const Container = styled.h2`
  object-fit: contain;
  color: #00224a;
  font-family: "Prudential Modern";
  font-size: 28px;
  font-stretch: normal;
  font-style: normal;
  font-weight: bold;
  letter-spacing: normal;
  line-height: 1.07;
  margin-bottom: 24px;
  margin-top: 24px;

  @media (min-width: 768px) {
    margin-bottom: 18px;
    margin-top: 40px;
  }
`;
