import React from 'react';

import { Container, HR } from './Title.styles';

// Interface
interface TitleProps {
	children: React.ReactNode;
}

const Title: React.FC<TitleProps> = ({ children }) => {
	return <Container>
		{children}
		<HR />
	</Container>;
}

export default Title;