import React from 'react';

// Style
import { Container } from './InformationTitle.styles';

// Interface
interface InformationTitleProps {
	children: React.ReactNode;
}

const InformationTitle: React.FC<InformationTitleProps> = ({ children }) => {
	return (<Container>{children}</Container>);
}

export default InformationTitle;