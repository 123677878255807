import styled, { css } from "styled-components";

interface Props {
  noDesktop?: boolean;
}

export const Container = styled.a<Props>`
  display: flex;
  align-items: center;
  color: #07639d;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  font-weight: 600;
  margin: 20px 12px;

  &:hover {
    text-decoration: underline;
  }

  svg {
    height: 24px;
    width: 24px;
  }

  @media (min-width: 600px) {
    ${({ noDesktop }) =>
      noDesktop &&
      css`
        display: none;
      `}
  }
`;
