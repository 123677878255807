import React from 'react';

// Assets
import facebook from "assets/img/facebook-f.svg"
import tiktok from "assets/img/tiktok.png"
import linkedin from "assets/img/linkedin-in.svg"
import instagram from "assets/img/instagram.svg"
import youtube from "assets/img/youtube.svg"

// Styles
import { Container } from './BoxSocials.styles';

// Components
import Anchor from 'components/atoms/Anchor/Anchor';
import SocialIcon from 'components/atoms/SocialIcon/SocialIcon';

const BoxSocials: React.FC = () => {
	return (
		<Container>
			<Anchor href='https://pt-br.facebook.com/Prudentialdobrasil/' target="_blank" title="Facebook - Prudential do Brasil" type="icon">
				<SocialIcon src={facebook} alt="Facebook - Prudential do Brasil" />
			</Anchor>

			<Anchor href='https://br.linkedin.com/company/prudentialdobrasil' target="_blank" title="Linkedin - Prudential do Brasil" type="icon">
				<SocialIcon src={linkedin} alt="Linkedin - Prudential do Brasil" />
			</Anchor>

			<Anchor href='https://www.instagram.com/prudentialdobrasil/' target="_blank" title="Instagram - Prudential do Brasil" type="icon">
				<SocialIcon src={instagram} alt="Instagram - Prudential do Brasil" />
			</Anchor>

			<Anchor href='https://www.youtube.com/channel/UCXq_3QaFgLK_M9HkbPmWgTQ' target="_blank" title="YouTube - Prudential do Brasil" type="icon">
				<SocialIcon src={youtube} alt="YouTube - Prudential do Brasil" />
			</Anchor>

			<Anchor href='https://www.tiktok.com/@prudentialdobrasil' target="_blank" title="TikTok - Prudential do Brasil" type="icon">
				<SocialIcon src={tiktok} alt="TikTok - Prudential do Brasil" />
			</Anchor>
		</Container>
	);
}

export default BoxSocials;