import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	margin: 8px 0;
`;

export const Logo = styled.img`
	width: 168px;
`;

export const SeparatorBox = styled.div`
	padding: 20px 0;

	svg {
		fill: #98c3de;
		height: auto;
		width: 34px;
	}
`;
