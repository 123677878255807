import { PermissionGroup, PermissionKeyName } from "./interfaces/home.interface";

export type SharedDataType = { [key: string]: PermissionGroup[] }

export type KeySharedDataType = keyof SharedDataType;

const registerDataBusinessKeys: PermissionKeyName[] = ['CUSTOMERS_BUSINESS_IDENTIFICATIONS_READ', 'CUSTOMERS_BUSINESS_QUALIFICATION_READ', 'CUSTOMERS_BUSINESS_ADDITIONALINFO_READ'];
const registerDataPersonalKeys: PermissionKeyName[] = ['CUSTOMERS_PERSONAL_IDENTIFICATIONS_READ', 'CUSTOMERS_PERSONAL_QUALIFICATION_READ', 'CUSTOMERS_PERSONAL_ADDITIONALINFO_READ'];
const financialAssistenceKeys: PermissionKeyName[] = ['FINANCIAL_ASSISTANCE_READ', 'FINANCIAL_ASSISTANCE_CONTRACTINFO_READ', 'FINANCIAL_ASSISTANCE_MOVEMENTS_READ'];
const transactionalDataKeys: PermissionKeyName[] = ['DAMAGES_AND_PEOPLE_PERSON_READ', 'DAMAGES_AND_PEOPLE_PERSON_POLICYINFO_READ', 'DAMAGES_AND_PEOPLE_PERSON_PREMIUM_READ', 'DAMAGES_AND_PEOPLE_PERSON_CLAIM_READ'];
const labelsOfGroup: { [key: string]: string } = {
    registerDataBusiness: 'Dados cadastrais - seguro coletivo',
    registerDataPersonal: 'Dados pessoais do Segurado',
    financialAssistence: 'Dados de Assistência Financeira',
    transactionalData: 'Dados do Seguro de Vida',
};

const keysOfGroup: { [key: KeySharedDataType]: PermissionKeyName[] } = {
    registerDataBusiness: registerDataBusinessKeys,
    registerDataPersonal: registerDataPersonalKeys,
    financialAssistence: financialAssistenceKeys,
    transactionalData: transactionalDataKeys,
}

export {
    registerDataBusinessKeys,
    registerDataPersonalKeys,
    financialAssistenceKeys,
    transactionalDataKeys,
    labelsOfGroup,
    keysOfGroup,
};
