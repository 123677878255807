import React from 'react';

// Style
import { Container } from './InformationContent.styles';

// Interface
interface InformationContentProps {
	children: React.ReactNode
}

const InformationContent: React.FC<InformationContentProps> = ({ children}) => {
	return (<Container className='text-bold'>{children}</Container>);
}

export default InformationContent;