import React from 'react';

// Styles
import { Container } from './AccordionContent.styles';

// Interface
interface AccordionContentProps {
	children: React.ReactNode;
	status: boolean;
}

const AccordionContent: React.FC<AccordionContentProps> = ({ children, status }) => {
	return (
		<Container status={status}>{children}</Container>
	);
}

export default AccordionContent;