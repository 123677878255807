import React from 'react';

// Styles
import { Container } from './ShareTitle.styles';

// Interface
interface ShareTitleProps {
	children: React.ReactNode;
}

const ShareTitle: React.FC<ShareTitleProps> = ({ children }) => {
	return (
		<Container>{children}</Container>
	);
}

export default ShareTitle;