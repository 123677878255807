import styled from 'styled-components';

// Interface
interface Props {
	status: 'base' | 'active';
}

export const Container = styled.div<Props>`
	background-color: ${({ status }) => status === 'base' ? '#D1D5DB' : '#16A34A'};
	border-radius: 100%;
	color: #1a1d1e;
	display: flex;
	font-size: 15px;
	height: 12px;
	justify-content: center;
	position: relative;
	width: 12px;

	@media (min-width: 600px) {
		&:first-child {
			p {
				left: 0;
			}
		}

		&:last-child {
			p {
				right: 0;
			}
		}
	}
`;

export const Paragraph = styled.p`
	position: absolute;
	margin-top: 12px;
	width: max-content;
`;

export const Name = styled.span`
	@media (max-width: 600px) {
		display: none;
	}
`;
