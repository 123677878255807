import './App.css';
import './index.css';
import { Helmet, HelmetProvider } from 'react-helmet-async'
import RoutesApp from './routes/Routes';

function App() {
  const dataDomain = process.env.REACT_APP_DATA_DOMAIN_SCRIPT;

  const isProduction = process.env.REACT_APP_PROFILE === "production";
  return (
    <HelmetProvider>
      <div>
        {(dataDomain && !isProduction) &&
          <Helmet>
            <script src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js" type="text/javascript" data-domain-script={dataDomain}></script>
          </Helmet>
        }
        <RoutesApp />
      </div>
    </HelmetProvider >
  );
}

export default App;
