import styled from 'styled-components';

export const Container = styled.div`
  background: #001f45;
  display: flex;
  justify-content: center;
  padding-bottom: 18px;
  padding-top:18px;
`;

export const Box = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 0 auto;
  max-width: 448px;
  padding: 0 18px;
  width: 100%;

  @media (min-width: 600px) {
    max-width: 100%;
  }
`;
