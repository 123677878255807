import styled from "styled-components";

export const Container = styled.div`
  padding: 0 16px 40px;

  @media (min-width: 768px) {
    margin: 0 auto;
    width: 80%;
  }
`;

export const AccordionBox = styled.div`
  margin-left: -16px;
  margin-right: -16px;
  margin-top: 20px;
`;
