import React from 'react';

// Styles
import { Container } from './TabsBox.styles';

// Interfaces
interface TabsBoxProps {
	children: React.ReactNode;
}

const TabsBox: React.FC<TabsBoxProps> = ({ children }) => {
	return (
		<Container>{children}</Container>
	);
}

export default TabsBox;