import { Consentimento, apiService } from "shared/services/generic/GenericService";
import { getDocumentType } from "utils/document.functions";

export const FINALIDADE =
    "Por meio da análise dos Dados compartilhados, a Prudential do Brasil poderá conhecer melhor o seu perfil para oferecer melhores produtos, serviços e condições personalizadas para atender as suas necessidades.";

export const SHARE_FOR_MONTHS = [
    { name: 'Selecione o período' },
    { id: 2, meses: 3, name: '3 meses' },
    { id: 3, meses: 6, name: '6 meses' },
    { id: 5, meses: 12, name: '12 meses' },
];

export const getPermissions = (permissions: string[]) => {
    return permissions.filter(p => p !== 'RESOURCES_READ')
        .map((p: string) => {
            let group_name = 'Identificação';
            if (p.indexOf('QUALIFICATION_READ') > -1) {
                group_name = 'Qualificação';
            }
            if (p.indexOf('ADDITIONALINFO_READ') > -1) {
                group_name = 'Relacionamento';
            }
            return { group_name, key_name: p };
        });
}

const getSeguradora = (consent: Consentimento) => {
    return apiService.getSeguradoras(consent.institutionName)
        .then(respOrg => respOrg.institutions.find(obj => obj.organizationId === consent.institution))
}

export function getInstitutionConsent(consentId: string) {
    return apiService.getConsentIdByConsentId(consentId)
        .then(respConsent =>
            getSeguradora(respConsent)
                .then(institution => {
                    if (!institution) return null;

                    const document = respConsent.document.replaceAll(/[^0-9]/g, "");
                    const meses = SHARE_FOR_MONTHS.find((p: any) => p.meses === parseInt(respConsent.deadLine.substring(0, 1)));
                    const sharedData = {
                        name: respConsent.name,
                        cpf: document,
                        finalidade: FINALIDADE,
                        seguro: {
                            name: institution!.name,
                            type: institution!.type,
                            logoUri: institution!.logoUri,
                            organizationId: institution!.organizationId,
                            authorizationUrl: institution!.authorizationUrl,
                            authorisationServerId: institution!.authorisationServerId
                        },
                        permissoes: getPermissions(respConsent.permissions),
                        meses: meses,
                        acceptedTerm: false,
                        documentType: getDocumentType(document)
                    };
                    return sharedData;
                })
        );
}
