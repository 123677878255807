import React from 'react';

// Styles
import { Container, Box } from './Step0.styles';

// Components
import FirstHead from 'components/molecules/FirstHead/FirstHead';
import FirstBoxButtons from 'components/molecules/FirstBoxButtons/FirstBoxButtons';
import FirstBoxAccordion from 'components/molecules/FirstBoxAccordion/FirstBoxAccordion';

const Step0: React.FC = () => {
	return (
		<Container>
			<Box>
				<FirstHead />
				<FirstBoxButtons />
				<FirstBoxAccordion />
			</Box>
		</Container>
	);
}

export default Step0;