import React, { AnchorHTMLAttributes } from "react";
import { useNavigate } from "react-router-dom";

// Styles
import { Container } from "./BackButton.styles";

// Assets
import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import { useCookies } from "react-cookie";

// Interface
interface BackButtonProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  children: React.ReactNode;
  nextStep?: (step: number) => void;
  currentStep?: number;
  urlCallBack?: string;
  noDesktop?: boolean;
}

const BackButton: React.FC<BackButtonProps> = ({
  children,
  currentStep = 1,
  nextStep,
  urlCallBack,
  noDesktop,
  ...rest
}) => {
  const navigate = useNavigate();
  const [, setCookie] = useCookies(["consentRenew", "consentIdForChange"]);

  const handleClick = () => {
    if (urlCallBack) {
      navigate(urlCallBack);
    } else {
      if (nextStep && currentStep === 1) {
        setCookie("consentIdForChange", null, {
          path: "/",
          expires: new Date(),
        });
        setCookie("consentRenew", null, {
          path: "/",
          expires: new Date(),
        });
        navigate(`/`);
      } else if (nextStep && currentStep > 1) {
        nextStep(currentStep - 1);
      } else {
        setCookie("consentIdForChange", null, {
          path: "/",
          expires: new Date(),
        });
        setCookie("consentRenew", null, {
          path: "/",
          expires: new Date(),
        });
        navigate(-1);
      }
    }
  };
  return (
    <Container
      data-testid="backBTN"
      onClick={handleClick}
      noDesktop={noDesktop}
      {...rest}
    >
      <ChevronLeftIcon />
      {children}
    </Container>
  );
};

export default BackButton;
