// Assets

import AlertMessage from 'components/molecules/AlertMessage/AlertMessage';
import StPCBoxContentInformation from 'components/molecules/BoxContentInformation/BoxContentInformation';
import StPCBoxTextContent from 'components/molecules/BoxTextContent/BoxTextContent';
import ModalParticipantes from "components/molecules/ModalParticipantes/ModalParticipantes";
import StPCInformationBox from 'components/organisms/InformationBox/InformationBox';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ApiService, HidratedConsentimento, Organizacao, sanitizeString } from 'shared/services/generic/GenericService';
// Styles
import { Button, Container } from './StepCallback.styles';
import BoxPermissions from 'components/molecules/BoxPermissions/BoxPermissions';
import { getDocumentType } from 'utils/document.functions';

const apiService = new ApiService();
const iconError = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224c0-17.7-14.3-32-32-32s-32 14.3-32 32s14.3 32 32 32s32-14.3 32-32z" /></svg>;
const iconSuccess = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" /></svg>;


const showDocumentFormated = (customerDocument: string) => {
	const document = customerDocument.replace(/[^0-9]/g, '');
	const type = getDocumentType(document)
	return <StPCBoxContentInformation title={type} content={customerDocument} />;
}

const StepCallback: React.FC = () => {
	const [shareStPCModal, setShareStPCModal] = useState<boolean>(false);
	const [carregando, setCarregando] = useState<boolean>(true);
	const [document, setDocument] = useState<string>('');
	const [organizacaoLoad, setOrganizacaoLoad] = useState<boolean>(true);
	const [messageReturn, setMessageReturn] = useState<any>();
	const [consentimento, setConsentimento] = useState<HidratedConsentimento>();
	const [organization, setOrganization] = useState<Organizacao>();
	const navigate = useNavigate();

	const [loadError, setLoadError] = useState<boolean>(false);
	const { hash } = useLocation();
	const params: any = Object.fromEntries(new URLSearchParams(sanitizeString(hash.substring(1))));
	const state = params.state;

	const loadData = () => {
		setCarregando(true);
		apiService.getConsentId(hash.substring(1))
			.then((consent: HidratedConsentimento) => {
				setDocument(consent.document)
				if (consent.status === 'AUTHORISED') {
					setMessageReturn({
						type: "success",
						icon: iconSuccess,
						title: "Sucesso",
						message: 'Sua solicitação de compartilhamento de dados tem até 2 dias úteis para ser efetivada.',
						success: false
					});
				} else {
					setMessageReturn({
						type: "error",
						icon: iconError,
						title: "Dados não compartilhados",
						message: 'Para concluir, é preciso que você confirme o compartilhamento na outra instituição. Por favor, tente novamente.',
						success: false
					});

				}
				setConsentimento(consent);
				apiService.getOrganization(consent.institution)
					.then((org: Organizacao) => {
						setOrganization(org);
					}).catch((error: any) => {
						setMessageReturn({
							type: "error",
							icon: iconError,
							title: "Erro ao gravar instituição!",
							message: error.response.data.errorDetail.errorContent.errors[0],
							success: false
						});
					}).finally(() => {
						setOrganizacaoLoad(false);
					});
			}).catch((error: any) => {
				if (error.response.status === 400) {
					setMessageReturn({
						type: "info",
						icon: iconError,
						title: "Atenção!",
						message: "Este consentimento já foi aprovado. Para mais detalhes consulte \"Meus Compartilhamentos\".",
						success: true
					});
					return;
				}
				setLoadError(true);
				setMessageReturn({
					type: "error",
					icon: iconError,
					title: "Erro ao receber consentimento!",
					message: error.response.data.errorDetail.errorContent.errors[0],
					success: false
				});
			}).finally(() => {
				setCarregando(false);
			});
	};

	useEffect(() => {
		loadData();
	}, [state]);

	const divLoad = (message: string = '', size: string = '2.5rem', height: string = 'auto') => {
		return (<>
			<div className="text-center" style={{ margin: "20px", "height": height }}>
				<div role="status">
					<svg style={{ "width": size }} className="inline mr-2 w-10 h-10 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
						<path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
					</svg>
					{message !== '' && <span className="sr-only" style={{ "overflow": "auto", "position": "inherit" }}>{message}</span>}
				</div>
			</div>
		</>)
	}

	if (carregando) {
		return (<Container>{divLoad('', '70px', '300px')}</Container>);
	}

	const getConfirmDate = (text: string) => {
		return text.replace("-", "às")
	}

	return (
		<Container>
			{messageReturn &&
				<AlertMessage type={messageReturn.type} icon={messageReturn.icon} title={messageReturn.title} content={messageReturn.message} />
			}

			{consentimento && <>
				<StPCBoxTextContent typeContent='title' textContent='Resumo da solicitação' />
				<div className='mt-6'></div>

				<StPCInformationBox direction='column'>
					<StPCBoxContentInformation title='Segurado' content={consentimento.name} />
					{showDocumentFormated(document)}
					<StPCBoxContentInformation title='Finalidade do uso de dados' content="Por meio da análise dos Dados compartilhados, a Prudential do Brasil poderá conhecer melhor o seu perfil para oferecer melhores produtos, serviços e condições personalizadas para atender as suas necessidades" />
					<Container>
						<StPCBoxContentInformation title='Instituição selecionada' content={consentimento.institutionName} />

						{organizacaoLoad && divLoad('Carregando participantes...', '20px')}
						{/* <StPCInformationLink data-testid="modalBTN" onClick={() => (setShareStPCModal(true))}>Ver detalhes do participante</StPCInformationLink> */}
					</Container>
					<div className='mt-3'></div>
					{consentimento.status !== 'REJECTED' && <StPCBoxContentInformation title='Data de confirmação' content={getConfirmDate(consentimento.confirmationDate)} />}
					{consentimento.status !== 'REJECTED' && <StPCBoxContentInformation title='Prazo de compartilhamento' content={consentimento.deadLine} />}
					<Container>
						<BoxPermissions permissoes={consentimento.permissions} />
					</Container>
				</StPCInformationBox>

				<ModalParticipantes organization={organization} setShareModal={setShareStPCModal} shareModal={shareStPCModal} />
			</>
			}

			<div className="w-full flex justify-center mb-6" style={{ "marginTop": "20px" }}>
				<Button data-testid="submitBTN" onClick={() => navigate(`/`)}>
					Nova Solicitação
				</Button>
			</div>

			{loadError && <div className="mx-auto w-full max-w-md rounded-2xl bg-white p-2">
				<div className="mss-load" onClick={() => loadData()}>Erro ao carregar, tentar novamente.</div>
			</div>}
		</Container>
	);
}

export default StepCallback; 