import React from "react";

import ButtonPagination from "components/atoms/ButtonPagination/ButtonPagination";

import { Container } from "./BoxPagination.styles";

interface BoxPaginationProps {
  totalPages: number[];
  handlePageChange: any;
  type: string;
  currentPage: number;
  status: "active" | "inactive";
}

const BoxPagination: React.FC<BoxPaginationProps> = ({
  totalPages,
  handlePageChange,
  type,
  currentPage,
  status,
}) => {
  return (
    <Container data-testid="boxPagination" status={status}>
      {totalPages?.map((pageNumber, i) => (
        <ButtonPagination
          key={i}
          pageNumber={pageNumber}
          handlePageChange={handlePageChange}
          tipo={type}
          isActive={pageNumber === currentPage ? true : false}
        />
      ))}
    </Container>
  );
};

export default BoxPagination;
