import React, { AnchorHTMLAttributes } from 'react';

import { Container } from './InformationLink.styles';

interface InformationLinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
	children: React.ReactNode;
}

const InformationLink: React.FC<InformationLinkProps> = ({ children, ...rest }) => {
	return (<Container {...rest}>{children}</Container>);
}

export default InformationLink;