import React from 'react';

// Styles
import { Container } from './PostTitle.styles';

// Interface
interface PostTitleProps {
	children: React.ReactNode;
}

const PostTitle: React.FC<PostTitleProps> = ({ children }) => {
	return <Container>{children}</Container>;
}

export default PostTitle;