import React from 'react';
import { ArrowRightCircleIcon } from '@heroicons/react/24/solid';
import Anchor from 'components/atoms/Anchor/Anchor';
import Text from 'components/atoms/Text/Text';
import TextStrong from 'components/atoms/TextStrong/TextStrong';
import Modal from 'components/organisms/Modal/Modal';
import { Organizacao } from '../../../shared/services/generic/GenericService';
import BoxGroupContent from '../BoxGroupContent/BoxGroupContent';

// Interface
interface ModalParticipantesProps {
    shareModal: boolean,
    setShareModal: (value: boolean) => void
    organization: Organizacao|undefined
}

const ModalParticipantes: React.FC<ModalParticipantesProps> = ({ shareModal, setShareModal, organization }) => {
    const getParticipantes = (org: Organizacao|undefined): string[] => {
        const participantes = org && org.participants && org.participants.length > 0 ? org.participants : ["Esta instituição possui apenas um participante"];

        return participantes;
    }

    return (
        <>{organization &&
            <Modal title='Detalhes do participante' type='alternative' showModal={shareModal} setShowModal={setShareModal}>
            <BoxGroupContent>
                <TextStrong>{organization?.organizationName}</TextStrong>
                <Text>CNPJ: {organization?.document}</Text>
            </BoxGroupContent>

            <BoxGroupContent>
                <TextStrong>Descrição da marca:</TextStrong>
                <Text>{organization?.description}</Text>
            </BoxGroupContent>

            <BoxGroupContent>
                <TextStrong>Lista de Participantes da Marca:</TextStrong>
                {getParticipantes(organization).map((participante: any, id: any) => (
                    <Text key={id}>{participante}</Text>
                ))}
            </BoxGroupContent>

            <BoxGroupContent>
                <Text>Para mais detalhes acesse o link abaixo:</Text>
            </BoxGroupContent>

            <BoxGroupContent align='center'>
                <Anchor type='blue' href='https://opinbrasil.com.br/' target="_blank"><ArrowRightCircleIcon /> Portal do Open Insurance</Anchor>
            </BoxGroupContent>
        </Modal>
        }</>
    );
}

export default ModalParticipantes;