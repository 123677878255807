import styled from "styled-components";

export const Container = styled.div`
  @media (min-width: 768px) {
    margin: 0 auto;
    width: 80%;
  }
`;

export const BoxFlags = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
