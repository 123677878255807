import React from 'react';

// Styles
import { Container, BoxFlags } from './BoxPostInformation.styles';

// Atoms
import PostTitle from 'components/atoms/PostTitle/PostTitle';
import PostFlag from 'components/atoms/PostFlag/PostFlag';
import PostDate from 'components/atoms/PostDate/PostDate';

// Interface
interface BoxPostInformationProps {
	title: string;
	reading: string;
	date: string;
}

const BoxPostInformation: React.FC<BoxPostInformationProps> = ({ title, reading, date }) => {
	return (
		<Container>
			<PostTitle>{title}</PostTitle>
			<BoxFlags>
				<PostFlag>Tempo de Leitura</PostFlag>
				<PostFlag type='text'>{reading}</PostFlag>
			</BoxFlags>
			<PostDate>{date}</PostDate>
		</Container>
	);
}

export default BoxPostInformation;