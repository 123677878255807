import React, { ImgHTMLAttributes } from 'react';

// Styles
import { Container } from './Logo.styles';

// Interface
interface LogoProps extends ImgHTMLAttributes<HTMLImageElement> {}

const Logo: React.FC<LogoProps> = ({...props}) => {
	return <Container {...props} />;
}

export default Logo;