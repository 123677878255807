import styled, { css } from "styled-components";

interface Props {
  quarterWidth?: boolean;
}

export const Container = styled.a<Props>`
  color: #0066cc;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (min-width: 600px) {
    ${({ quarterWidth }) =>
      quarterWidth &&
      css`
        flex-basis: 22%;
      `}
  }
`;
